import React from "react";
import PropTypes from "prop-types";
import GenericCard from "../UI/GenericCard/GenericCard";
import HoverableTooltip from "components/UI/Tooltip/HoverableTooltip";

const VarianceCard = (props) => {
  const { backtestResults } = props;

  return (
    <GenericCard title="Average Annual PNL">
      <h2>{backtestResults.metrics.yearlyPnl.average.toLocaleString()}%</h2>
      <h5 className="pt-3">
        Average Monthly PNL{" "}
        <HoverableTooltip text="Includes only months with closed trades" />
      </h5>
      <h2>{backtestResults.metrics.monthlyPnl.average.toLocaleString()}%</h2>
    </GenericCard>
  );
};
VarianceCard.propTypes = {
  backtestResults: PropTypes.object,
};

export default VarianceCard;
