import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import GenericCard from "components/UI/GenericCard/GenericCard";
import { Button, Col, Input, Row } from "antd";
import dayjs from "dayjs";
import AntTable from "components/UI/AntTable";
import ExportCsvButton from "components/UI/ExportCsvButton";
import { returnUpDownClassname } from "utils/helpers";

const PredictionsTable = ({ data, isOpen = true }) => {
  const [searchDateInput, setSearchDateInput] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const filteredList = useRef();

  const sortByNumber = useCallback((a, b) => {
    // handle null / N/A values
    if (a === b) return 0;
    if (a === null || a === "N/A") return -1;
    if (b === null || b === "N/A") return 1;
    return a > b ? 1 : -1;
  }, []);

  const sortByText = useCallback((a, b) => {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  }, []);

  const dataSorted = useMemo(() => data?.slice()?.reverse() || [], [data]);

  const columns = [
    {
      title: "Symbol",
      dataIndex: "symbol",
      sorter: {
        compare: (a, b) => sortByText(a.symbol, b.symbol),
      },
    },
    {
      title: "Known Result",
      dataIndex: "buy_sell",
      render: (text) => (
        <span
          className={`${text === "no buy" ? "red" : ""} ${
            text === "buy" ? "green" : ""
          }`}
        >
          {text}
        </span>
      ),
      sorter: {
        compare: (a, b) => sortByText(a.buy_sell, b.buy_sell),
      },
    },
    {
      title: "Prediction",
      dataIndex: "prediction",
      render: (text) => (
        <span className={text === "buy" ? "green" : "red"}>{text}</span>
      ),
      sorter: {
        compare: (a, b) => sortByText(a.prediction, b.prediction),
      },
    },
    {
      title: "Close",
      dataIndex: "close",
      sorter: {
        compare: (a, b) => sortByNumber(a.close, b.close),
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (text) => <>{dayjs(text).format("DD/MM/YY")}</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.startDate, b.startDate),
      },
    },
  ];

  const scrollToSearch = () => {
    const idx = filteredList.current.findIndex((item) =>
      dayjs(item.date).format("DD/MM/YY").includes(searchDateInput)
    );
    if (idx >= 0) {
      document.querySelector(
        "div.predictions-table div.ant-table-body"
      ).scrollTop = idx * 47.23;
      setSelectedDate(filteredList.current[idx].date);
    }
  };

  useEffect(() => {
    filteredList.current = dataSorted;
    setSearchDateInput("");
  }, [dataSorted]);

  return (
    <GenericCard
      title="Predictions Table"
      className="table-card"
      maxHeight={700}
      isCardOpenByDefault={isOpen}
    >
      <div className="results-table-buttons">
        <Row gutter={8}>
          <Col>
            <Input
              placeholder="Search date"
              value={searchDateInput}
              onChange={(e) => setSearchDateInput(e.target.value)}
              onPressEnter={scrollToSearch}
              style={{
                width: 150,
              }}
            />
          </Col>
          <Col>
            <Button type="primary" onClick={scrollToSearch}>
              Search
            </Button>
          </Col>
        </Row>
      </div>
      <AntTable
        rootClassName="predictions-table"
        rowKey="_id"
        dataSource={dataSorted}
        columns={columns}
        onChange={(pagination, filters, sorter, extra) => {
          filteredList.current = extra.currentDataSource;
        }}
        rowClassName={(obj) =>
          obj.date === selectedDate ? "table-row-selected" : ""
        }
        scroll={{
          x: 200,
          y: 400,
        }}
      />
      <div className="pt-4">
        <ExportCsvButton data={dataSorted} fileName="predictions" />
      </div>
    </GenericCard>
  );
};

export default PredictionsTable;
