import React, { useEffect } from "react";
import ChangePremiumFee from "./ChangePremiumFee";
import ChangePassword from "./ChangePassword";
import AutomationOnboarding from "./AutomationOnboarding";
import { useSelector } from "react-redux";
import { selectAutomationAllowed } from "store/slices/main";

const UserSettings = () => {
  const automationAllowed = useSelector(selectAutomationAllowed);

  useEffect(() => {
    document.title = "Settings | Dindicator Dashboard";
  }, []);

  return (
    <>
      <div className="cards-wrapper">
        <ChangePassword />
        <ChangePremiumFee />
      </div>
      {automationAllowed && <AutomationOnboarding />}
    </>
  );
};

export default UserSettings;
