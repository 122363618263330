import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { onLoginSubmit } from "store/asyncThunk";
import { paths } from "utils/constants";
import { Button, Card, Form, Input } from "antd";
import TrendLogo from "assets/trending.png";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const user = useSelector((state) => state.main.user);
  const [form] = Form.useForm();

  const [errorMessage, setErrorMessage] = useState("");

  const onLogin = async (values) => {
    try {
      await dispatch(onLoginSubmit(values)).unwrap();
      navigate(state?.from?.pathname + state?.from?.search || paths.home);
    } catch (err) {
      setErrorMessage(err?.message || "Something went wrong");
    }
  };

  const redirectIfUserLoggedIn = useCallback(() => {
    // if already logged in redirect to home
    if (user) navigate(paths.home);
  }, [navigate, user]);

  useEffect(() => {
    redirectIfUserLoggedIn();
  }, [redirectIfUserLoggedIn]);

  useEffect(() => {
    document.title = "Login | Dindicator Dashboard";
  }, []);

  if (user) return <></>;

  return (
    <div className="flex items-center justify-center h-full">
      <Card className="rounded-lg p-4 max-w-[500px] w-full">
        <div className="flex flex-col items-center">
          <div className="flex items-center pe-5 pb-5 select-none">
            <img src={TrendLogo} alt="" className="h-10" />
            <h1 className="font-[Kalam] text-3xl pt-2">Dindicator</h1>
          </div>
          <h2 className="text-3xl font-bold mb-4 text-center">
            Welcome to Dindicator!
          </h2>
          <p className="text-center mb-4 text-lg text-gray">
            Log in to your account
          </p>
        </div>

        <Form form={form} layout="vertical" name="register" onFinish={onLogin}>
          <Form.Item
            name="username"
            label="Email Address"
            required={false}
            rules={[
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="password"
            label="Password"
            required={false}
            rules={[
              {
                required: true,
                message: "Please enter your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item shouldUpdate className="pt-2">
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                className="w-full"
                size="large"
                disabled={
                  !form.isFieldsTouched(true) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length)
                    .length
                }
              >
                Login
              </Button>
            )}
          </Form.Item>
          {errorMessage && (
            <div className="text-[#ff0000] mt-2">
              <p>{errorMessage} </p>
            </div>
          )}
        </Form>
      </Card>
    </div>
  );
};

export default Login;
