import React, { useCallback, useEffect, useState } from "react";
import { apiGetServerConnectionsList } from "api/apiAdmin";

import GenericCard from "../UI/GenericCard/GenericCard";
import AntTable from "components/UI/AntTable";
import dayjs from "dayjs";

const ConnectionsTable = () => {
  const [connections, setConnections] = useState([]);

  const getConnections = async () => {
    const data = await apiGetServerConnectionsList();
    if (data) setConnections(data);
  };

  useEffect(() => {
    getConnections();
  }, []);

  const sortByNumber = useCallback((a, b) => {
    // handle null / N/A values
    if (a === b) return 0;
    if (a === null || a === "N/A") return -1;
    if (b === null || b === "N/A") return 1;
    return a > b ? 1 : -1;
  }, []);

  const sortByText = useCallback((a, b) => {
    return a?.toLowerCase()?.localeCompare(b?.toLowerCase());
  }, []);

  const columns = [
    {
      title: "IP",
      dataIndex: "ip",
      render: (text) => (
        <a
          href={`https://whatismyipaddress.com/ip/${text}`}
          target="_blank"
          rel="noreferrer"
        >
          {text}
        </a>
      ),
      sorter: {
        compare: (a, b) => sortByText(a.ip, b.ip),
      },
    },
    {
      title: "User",
      dataIndex: "username",
      sorter: {
        compare: (a, b) => sortByText(a?.username, b?.username),
      },
    },
    {
      title: "User Agent",
      dataIndex: "agent",
      sorter: {
        compare: (a, b) => sortByText(a.agent, b.agent),
      },
    },
    {
      title: "Language",
      dataIndex: "lang",
      sorter: {
        compare: (a, b) => sortByText(a.lang, b.lang),
      },
    },
    {
      title: "Count",
      dataIndex: "count",
      sorter: {
        compare: (a, b) => sortByNumber(a.count, b.count),
      },
    },
    {
      title: "Last Login",
      dataIndex: "lastLogin",
      render: (text) => <>{dayjs(text).format("HH:mm:ss - DD/MM/YY")}</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.lastLogin, b.lastLogin),
      },
    },
  ];

  return (
    <GenericCard title="Connections list" className="table-card">
      <AntTable
        dataSource={connections}
        columns={columns}
        scroll={{
          x: 600,
          y: 400,
        }}
      />
    </GenericCard>
  );
};

export default ConnectionsTable;
