import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Select } from "antd";
import { apiGetSymbolRecommendations } from "api/apiPredictions";
import dayjs from "dayjs";

const SymbolCheckup = () => {
  const [symbol, setSymbol] = useState("");
  const [symbolRecommendations, setSymbolRecommendations] = useState(null);

  const symbolsGroupedInSectored = useSelector(
    (state) => state.main.symbolsGroupedInSectored
  );

  const getSymbolRecommendations = async () => {
    const response = await apiGetSymbolRecommendations(symbol);
    setSymbolRecommendations(response);
  };

  useEffect(() => {
    if (symbol) {
      getSymbolRecommendations();
    }
  }, [symbol]);

  return (
    <div className="pt-4">
      <div className="pb-2">Check AI recommendations:</div>
      <Select
        placeholder="Enter symbol name"
        showSearch
        dropdownMatchSelectWidth={252}
        options={symbolsGroupedInSectored}
        filterOption={(inputValue, option) =>
          option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        style={{ width: 200 }}
        allowClear
        onSelect={(value) => setSymbol(value)}
      />
      {symbolRecommendations && (
        <div className="pt-4 gap-1 flex flex-col">
          <div>
            {symbol}, {dayjs(symbolRecommendations.date).format("DD/MM/YYYY")}:
          </div>
          <div className={symbolRecommendations.buyMajority ? "green" : ""}>
            Buy: {symbolRecommendations.buy}
          </div>
          <div className={symbolRecommendations.buyMajority ? "" : "red"}>
            No Buy: {symbolRecommendations.noBuy}
          </div>
        </div>
      )}
    </div>
  );
};

export default SymbolCheckup;
