import axios from "utils/axios";
import { routes } from "api/routes";

export const apiCreateNewModel = async (newModelData) => {
  const { data } = await axios.post(routes.createNewModel, newModelData);
  return data;
};

export const apiGetModelsList = async () => {
  const { data } = await axios.get(routes.getModelsList);
  return data;
};

export const apiDeleteModel = async (modelId) => {
  const { data } = await axios.delete(routes.deleteModelById, {
    params: { modelId },
  });
  return data;
};

export const apiGetModelDetails = async (modelId) => {
  const { data } = await axios.get(routes.getModelDetails, {
    params: { modelId },
  });
  return data;
};
