import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { Button, InputNumber, Radio } from "antd";

// import { Histogram } from "@ant-design/plots";

const MonteCarloSimulation = (props) => {
  const { backtestResults } = props;
  const [chartData, setChartData] = useState([]);
  const [statistics, setStatistics] = useState({});
  const [runAgainCounter, setRunAgainCounter] = useState(0);
  const [numSimulations, setNumSimulations] = useState(1000);
  const [maxSimulationsToPlot, setMaxSimulationsToPlot] = useState(20);
  const [tradesSource, setTradesSource] = useState("backtest");

  // Parameters for the strategy
  const winRate = backtestResults.metrics.successRate / 100;
  const avgProfit = backtestResults.metrics.avgProfit / 100;
  const avgLoss = backtestResults.metrics.avgLoss / 100;
  const numTrades = backtestResults.metrics.totalTransactions;
  const initialCapital = backtestResults.initialBalance;

  // Helper function to calculate the median
  function calculateMedian(arr) {
    const sorted = arr.slice().sort((a, b) => a - b);
    const mid = Math.floor(sorted.length / 2);
    return sorted.length % 2 !== 0
      ? sorted[mid]
      : (sorted[mid - 1] + sorted[mid]) / 2;
  }

  // Helper function to calculate percentiles
  function calculatePercentile(arr, percentile) {
    const sorted = arr.slice().sort((a, b) => a - b);
    const index = Math.floor((percentile / 100) * sorted.length);
    return sorted[index];
  }

  // Simulate a single series of trades and return cumulative results
  function simulateTrades() {
    let results = [];
    let capital = initialCapital;

    for (let i = 0; i < numTrades; i++) {
      let tradeOutcome = Math.random() < winRate ? avgProfit : avgLoss;
      capital *= 1 + tradeOutcome; // Compound interest reinvestment
      let percentageGain = ((capital - initialCapital) / initialCapital) * 100; // Calculate percent gain
      results.push(percentageGain);
    }

    return results;
  }

  function returnTradesProfitRandom() {
    let results = [];
    let capital = initialCapital;
    let transactions = backtestResults.transactions;

    for (let i = 0; i < transactions.length; i++) {
      const randomProfit =
        transactions[Math.floor(Math.random() * transactions.length)].netProfit
          .perc / 100;

      capital *= 1 + randomProfit; // Compound interest reinvestment
      let percentageGain = ((capital - initialCapital) / initialCapital) * 100; // Calculate percent gain
      results.push(percentageGain);
    }

    return results;
  }

  // Run the simulation multiple times
  function runSimulations() {
    let allSimulations = [];

    for (let i = 0; i < numSimulations; i++) {
      allSimulations.push(
        tradesSource === "backtest"
          ? returnTradesProfitRandom()
          : simulateTrades()
      );
    }

    return allSimulations;
  }

  // Prepare data for React chart
  function prepareChartData(simulationData) {
    let chartData = [];

    for (let i = 0; i < numTrades; i++) {
      let tradeData = { tradeNumber: i + 1 };

      simulationData.forEach((simulation, index) => {
        tradeData[`Simulation_${index + 1}`] = simulation[i];
      });

      chartData.push(tradeData);
    }

    return chartData;
  }

  // Calculate summary statistics from final returns
  function calculateStatistics(simulationData) {
    let finalReturns = simulationData.map((sim) => sim[sim.length - 1]);

    const highestProfit = Math.max(...finalReturns);
    const lowestProfit = Math.min(...finalReturns);
    const averageProfit =
      finalReturns.reduce((acc, value) => acc + value, 0) / finalReturns.length;
    const medianProfit = calculateMedian(finalReturns);
    const standardDeviation = Math.sqrt(
      finalReturns.reduce(
        (acc, value) => acc + Math.pow(value - averageProfit, 2),
        0
      ) / finalReturns.length
    );
    const percentile25 = calculatePercentile(finalReturns, 25);
    const percentile75 = calculatePercentile(finalReturns, 75);

    const probabilityOfLoss =
      (finalReturns.filter((value) => value < 0).length / finalReturns.length) *
      100;

    return {
      highestProfit,
      lowestProfit,
      averageProfit,
      medianProfit,
      standardDeviation,
      percentile25,
      percentile75,
      probabilityOfLoss,
    };
  }

  useEffect(() => {
    // Run the simulations and prepare data for the chart and metrics
    if (backtestResults) {
      const simulationData = runSimulations();
      const preparedData = prepareChartData(simulationData);
      const stats = calculateStatistics(simulationData);
      setChartData(preparedData);
      setStatistics(stats);
    }
  }, [backtestResults, runAgainCounter, numSimulations, tradesSource]);

  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full md:w-1/2 lg:w-1/3 p-4">
          <h3 className="text-lg font-semibold">Summary Statistics</h3>
          <ul className="list-disc list-inside">
            <li>
              Highest Profit: {statistics?.highestProfit?.toLocaleString()}%
            </li>
            <li>Highest Loss: {statistics?.lowestProfit?.toLocaleString()}%</li>
            <li>
              Average Profit: {statistics?.averageProfit?.toLocaleString()}%
            </li>
            <li>
              Median Profit: {statistics?.medianProfit?.toLocaleString()}%
            </li>
            <li>
              Standard Deviation: {statistics?.standardDeviation?.toFixed(2)}
            </li>
            <li>25th Percentile: {statistics?.percentile25?.toFixed(2)}%</li>
            <li>75th Percentile: {statistics?.percentile75?.toFixed(2)}%</li>
            <li>
              Probability of Loss: {statistics?.probabilityOfLoss?.toFixed(2)}%
            </li>
          </ul>
        </div>
        <div className="w-full md:w-1/2 lg:w-2/3 p-4">
          <h3 className="text-lg font-semibold">
            Monte Carlo Simulation Chart
          </h3>
          <p>
            This chart shows the cumulative returns of {numSimulations}{" "}
            simulated trading strategies. Each line represents a different
            simulation run.
          </p>
          <div className="flex gap-10">
            <div>
              <label className="block mt-4">Number of Simulations</label>
              <div className="pt-2">
                <InputNumber
                  value={numSimulations}
                  onChange={setNumSimulations}
                  max={100000}
                />
              </div>
            </div>
            <div>
              <label className="block mt-4">Maximum Simulations to Plot</label>
              <div className="pt-2">
                <InputNumber
                  value={maxSimulationsToPlot}
                  onChange={setMaxSimulationsToPlot}
                  max={Math.min(numSimulations, 200)}
                />
              </div>
            </div>
            <div>
              <label className="block mt-4">Trades Source</label>
              <Radio.Group
                onChange={(e) => setTradesSource(e.target.value)}
                value={tradesSource}
              >
                <Radio value="backtest">Backtest</Radio>
                <Radio value="simulated">Simulated</Radio>
              </Radio.Group>
            </div>
          </div>

          <div className="pt-4">
            <Button onClick={() => setRunAgainCounter(runAgainCounter + 1)}>
              Run Again
            </Button>
          </div>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={800}>
        <LineChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="tradeNumber"
            label={{
              value: "Trade Number",
              position: "insideBottomRight",
              offset: 0,
            }}
          />
          <YAxis
            label={{
              value: "Cumulative Return",
              angle: -90,
              position: "insideLeft",
            }}
          />
          <Tooltip />
          {/* <Legend /> */}

          {Array.from(
            {
              length: Math.min(maxSimulationsToPlot, numSimulations),
            },
            (_, index) => (
              <Line
                key={index}
                type="monotone"
                dataKey={`Simulation_${index + 1}`}
                stroke={`#${Math.floor(Math.random() * 16777215).toString(16)}`} // Random colors
                dot={false}
              />
            )
          )}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

MonteCarloSimulation.propTypes = { backtestResults: PropTypes.object };

export default MonteCarloSimulation;
