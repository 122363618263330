import React, { useEffect, useState } from "react";
import { apiCheckIfIBCConfigExists } from "api/apiTws";
import LinkTelegramAccount from "./LinkTelegramAccount";
import UpdateIBKRDetails from "./UpdateIBKRDetails";
import TwsStatus from "components/AdminDashboard/SystemControl/TwsStatus";
import AutomationRunningStatus from "./AutomationRunningStatus";
import { Divider } from "antd";
import { useSelector } from "react-redux";
import AutomationToken from "./AutomationToken";

const AutomationOnboarding = () => {
  const [flowStatus, setFlowStatus] = useState(null);
  const automationHosting = useSelector(
    (state) => state.main.user.automation.hosting
  );

  const checkOnboardingFlow = async () => {
    try {
      const response = await apiCheckIfIBCConfigExists();
      setFlowStatus(response);
    } catch (err) {
      console.error(err);
    }
  };

  const stepsComponents = [<LinkTelegramAccount />];

  if (automationHosting) {
    stepsComponents.push(
      <UpdateIBKRDetails
        completed={flowStatus?.step > 1}
        checkOnboardingFlow={checkOnboardingFlow}
      />
    );
  } else {
    stepsComponents.push(<AutomationToken />);
  }

  useEffect(() => {
    checkOnboardingFlow();
  }, []);

  if (automationHosting && !flowStatus) return <div>Loading...</div>;

  return (
    <>
      <Divider orientation="left">Automation Settings</Divider>
      {automationHosting && flowStatus?.ready && (
        <div>
          <div className="cards-wrapper">
            <TwsStatus />
            <AutomationRunningStatus />
          </div>
          <div className="green pb-6 text-lg">
            You are all set up! You can now start using the automation features.
          </div>
        </div>
      )}

      <div className="cards-wrapper">
        {stepsComponents.map((step, index) => {
          if (index > flowStatus?.step) return null;
          return <div key={index}>{step}</div>;
        })}
      </div>
    </>
  );
};

export default AutomationOnboarding;
