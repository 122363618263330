import io from "socket.io-client";
const socket = io({ path: "/socket", autoConnect: false });

export const connectSocket = () => {
  if (!socket.connected) {
    socket.connect();
  }
};

socket.on("connect", () => {
  socket.emit("update_location", document.title.split(" |")[0]);
});

socket.on("refresh_platform", () => {
  window.location.reload();
});

const observer = new MutationObserver((mutations) => {
  const location = mutations[0].target.text.split(" |")[0];
  if (location) {
    socket.emit("update_location", location);
  }
});
observer.observe(document.querySelector("title"), {
  subtree: true,
  characterData: true,
  childList: true,
});

export default socket;
