import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import HoverableTooltip from "components/UI/Tooltip/HoverableTooltip";
import moment from "moment";
import { apiGetModelDetails } from "api/apiAiModels";

const ModelDetails = (props) => {
  const { model } = props;
  const [open, setOpen] = useState(false);
  const [modelDetails, setModelDetails] = useState(null);

  const getDetails = async () => {
    const response = await apiGetModelDetails(model._id);
    setModelDetails(response);
  };

  useEffect(() => {
    if (open && !modelDetails) {
      getDetails();
    }
  }, [open]);

  return (
    <>
      <HoverableTooltip text={model.name} onClick={() => setOpen(true)} />
      {modelDetails && (
        <Modal
          title={model.name}
          open={open}
          footer={null}
          width={800}
          destroyOnClose={true}
          onCancel={() => setOpen(false)}
        >
          <h3>Trained on</h3>
          <p>{modelDetails.leads?.map((stock) => `${stock}, `)}</p>
          <br />
          <h3>Trained data</h3>
          <p>
            {moment(modelDetails.results?.start_date).format("DD/MM/YY")}
            {" - "}
            {moment(modelDetails.results?.end_date).format("DD/MM/YY")}
          </p>
          <br />
          <h3>Profit target:</h3>
          <p style={{ color: "#ffffff" }}>
            {((modelDetails.results?.perc_to_gain - 1) * 100).toFixed()}% in{" "}
            {modelDetails.results?.days} days
          </p>
          <br />
          <h3>Buy % from total</h3>
          <p>{(modelDetails?.results?.buy_from_total * 100).toFixed(2)}%</p>
          <br />
          <p>
            {modelDetails?.creator
              ? `Created by ${modelDetails.creator.firstName} ${modelDetails?.creator?.lastName}, `
              : ""}
            {moment(modelDetails.date).format("HH:mm DD/MM/YY")}
          </p>
        </Modal>
      )}
    </>
  );
};

ModelDetails.propTypes = {
  model: PropTypes.object.isRequired,
};

export default ModelDetails;
