import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";

import { Tabs } from "antd";

import PredictionsTable from "./PredictionsTable";
import ModelPerformance from "./ModelPerformance";
import AiBacktestSection from "./AiBacktestSection";

const defaultTab = "backtest";

const BacktestStrategy = (props) => {
  const { results, setStrategyResults, modelsList, handleSelectModel } = props;
  const [selectedTab, setSelectedTab] = useState(defaultTab);

  const items = useMemo(() => {
    const tabOptions = results
      ? [
          {
            key: "backtest",
            label: `Backtest`,
            children: (
              <AiBacktestSection
                results={results}
                setStrategyResults={setStrategyResults}
                modelsList={modelsList}
                handleSelectModel={handleSelectModel}
              />
            ),
          },
          {
            key: "predictions",
            label: `Predictions`,
            children: <PredictionsTable data={results.predictionsTable} />,
          },
          {
            key: "performance",
            label: `Performance`,
            children: (
              <ModelPerformance predictions={results.predictionsTable} />
            ),
          },
        ]
      : [];
    return tabOptions;
  }, [results]);

  const handleChangeTab = (tabKey) => {
    setSelectedTab(tabKey);
  };
  return (
    <Tabs items={items} activeKey={selectedTab} onChange={handleChangeTab} />
  );
};

BacktestStrategy.propTypes = {
  results: PropTypes.object,
  setStrategyResults: PropTypes.array,
};

export default BacktestStrategy;
