import React, { useMemo } from "react";
import PropTypes from "prop-types";

import GenericCard from "components/UI/GenericCard/GenericCard";

import { Histogram } from "@ant-design/plots";

const ProfitHistogram = (props) => {
  const { backtestResults } = props;

  const data = useMemo(() => {
    return backtestResults.transactions.map((transaction) => {
      return { value: transaction.profit.perc };
    });
  }, [backtestResults.transactions]);

  const config = {
    data,
    style: {
      inset: 0.5,
    },
    binField: "value",
    channel: "count",
    binWidth: 1,
    scale: {
      x: { domainMin: 0, tickCount: 10 },
      y: {
        domainMax: 15,
        nice: true,
      },
    },
  };

  return (
    <GenericCard
      title="Profit Histogram"
      maxHeight={1200}
      isCardOpenByDefault={false}
      className="table-card"
    >
      <Histogram {...config} autoFit={false} className="!h-[420px]" />
    </GenericCard>
  );
};

ProfitHistogram.propTypes = { backtestResults: PropTypes.object };

export default ProfitHistogram;
