import React from "react";
import PropTypes from "prop-types";
import GenericCard from "../UI/GenericCard/GenericCard";

const TotalPositionsClosedWithProfit = (props) => {
  const { backtestResults } = props;

  return (
    <GenericCard title="Closed with profit">
      <div className="flex gap-6">
        <div>
          <h5>Any profit</h5>
          <h4>
            {backtestResults.metrics.closedWithProfit} /{" "}
            {backtestResults.metrics.totalTransactions}
          </h4>
          <div>
            <h5>Success rate</h5>
            <h3>{backtestResults.metrics.successRate}%</h3>
          </div>
        </div>

        <div>
          <h5>{backtestResults.params.takeProfitPercent}% target</h5>
          <h4>
            {backtestResults.metrics.reachedModelTarget} /{" "}
            {backtestResults.metrics.totalTransactions}
          </h4>
          <div>
            <h5>Success rate</h5>
            <h3>{backtestResults.metrics.strategySuccessRate}%</h3>
          </div>
        </div>
      </div>
    </GenericCard>
  );
};
TotalPositionsClosedWithProfit.propTypes = {
  backtestResults: PropTypes.object,
};

export default TotalPositionsClosedWithProfit;
