import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { apiFetchTaskLogs } from "api/apiAdmin";
import AntTable from "components/UI/AntTable";
import dayjs from "dayjs";

const TaskLogsModal = (props) => {
  const { taskId } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [logsContent, setLogsContent] = useState([]);

  const sortByNumber = useCallback((a, b) => {
    // handle null / N/A values
    if (a === b) return 0;
    if (a === null || a === "N/A") return -1;
    if (b === null || b === "N/A") return 1;
    return a > b ? 1 : -1;
  }, []);

  const columns = [
    {
      title: "Level",
      dataIndex: "level",
    },
    {
      title: "Message",
      dataIndex: "message",
    },
    {
      title: "Task",
      dataIndex: "eventType",
    },
    {
      title: "Sub Task",
      dataIndex: "taskType",
    },
    {
      title: "Time",
      dataIndex: "time",
      render: (text) => <>{dayjs(text).format("DD/MM/YY HH:mm:ss")}</>,
      sorter: {
        compare: (a, b) => sortByNumber(a.time, b.time),
      },
    },
  ];

  const getLogs = async () => {
    try {
      const logs = await apiFetchTaskLogs(taskId);
      setLogsContent(logs);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    let intervalId;
    if (isOpen) {
      getLogs();
      intervalId = setInterval(getLogs, 2000);
    } else {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [isOpen]);

  return (
    <>
      <Button type="default" onClick={() => setIsOpen(true)}>
        View Logs
      </Button>
      <Modal
        title="Task Logs"
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        width={1400}
        destroyOnClose={true}
      >
        <div>
          <Button onClick={getLogs} className="mb-4">
            Refresh
          </Button>
          <AntTable
            rootClassName="predictions-table"
            rowKey="_id"
            dataSource={logsContent}
            columns={columns}
            scroll={{
              x: 1200,
              y: 400,
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default TaskLogsModal;
