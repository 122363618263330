import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import AppComponent from "./App";
import configureStore from "./store/Store";
import { App, ConfigProvider, theme } from "antd";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={configureStore}>
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorBgBase: "#181922",
          // fontSize: 11,

          // colorBgContainer: "#21222d",
        },
      }}
    >
      <App>
        <BrowserRouter>
          <AppComponent />
        </BrowserRouter>
      </App>
    </ConfigProvider>
  </Provider>
);
