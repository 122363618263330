import React, { useEffect, useState } from "react";
import { apiGetTopPerformers } from "api/apiStrategies";
import LeaderboardsTable from "./LeaderboardsTable";

const Leaderboards = () => {
  const [strategies, setStrategies] = useState([]);

  const fetchStrategies = async () => {
    const data = await apiGetTopPerformers();
    setStrategies(data);
  };

  useEffect(() => {
    fetchStrategies();

    document.title = "Leaderboards | Dindicator Dashboard";
  }, []);

  return (
    <div>
      <LeaderboardsTable strategies={strategies} selectedIds={[]} />
    </div>
  );
};

export default Leaderboards;
