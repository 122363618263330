import React, { useEffect, useState } from "react";
import { Divider } from "antd";
import OnlineUsers from "components/Home/OnlineUsers";
import InviteNewUser from "./InviteNewUser";
import UserCard from "./UserCard";
import UsersTable from "./UsersTable";
import { useNavigate, useParams } from "react-router-dom";
import { paths } from "utils/constants";

const UsersManagement = () => {
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();
  const { id: userId } = useParams();

  const handleSelectUser = (userId) => {
    setSelectedUserId(userId);
    if (userId) {
      navigate(`${paths.usersManagement}/${userId}`);
    } else {
      navigate(`${paths.usersManagement}`);
    }
  };

  useEffect(() => {
    if (userId) {
      setSelectedUserId(userId);
      handleSelectUser(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (!selectedUserId) {
      document.title = "Users | Dindicator Dashboard";
    }
  }, [selectedUserId]);

  return (
    <>
      <InviteNewUser />
      <div className="pt-4">
        <OnlineUsers title="Online users" handleSelectUser={handleSelectUser} />
      </div>
      {selectedUserId ? (
        <div className="pt-4">
          <UserCard
            userId={selectedUserId}
            onBackButton={() => {
              handleSelectUser(null);
            }}
          />
        </div>
      ) : (
        <>
          <Divider orientation="left">Users</Divider>
          <UsersTable
            handleSelectUser={handleSelectUser}
            searchResults={searchResults}
            setSearchResults={setSearchResults}
          />
        </>
      )}
    </>
  );
};

export default UsersManagement;
