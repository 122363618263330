import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { apiGetAutomationLogs } from "api/apiTws";

const AutomationLogsModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [logsContent, setLogsContent] = useState("");

  const getLogs = async () => {
    try {
      const logs = await apiGetAutomationLogs();
      const logLines = logs.split("\n");
      const reversedLogLines = logLines.reverse();
      const reversedLogContent = reversedLogLines.join("\n");
      setLogsContent(reversedLogContent);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    let intervalId;
    if (isOpen) {
      getLogs();
      intervalId = setInterval(getLogs, 5000);
    } else {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [isOpen]);

  return (
    <>
      <Button type="default" onClick={() => setIsOpen(true)}>
        View Logs
      </Button>
      <Modal
        title="Logs"
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        width={800}
        destroyOnClose={true}
      >
        <div>
          <Button onClick={getLogs}>Refresh</Button>
          <div className="pt-4 overflow-auto">
            <pre>{logsContent}</pre>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AutomationLogsModal;
