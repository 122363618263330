import React from "react";
import PropTypes from "prop-types";
import GenericCard from "../UI/GenericCard/GenericCard";
import { formatNumber } from "utils/helpers";

const TotalTransactions = (props) => {
  const { backtestResults } = props;

  return (
    <GenericCard title="Transactions">
      <div>
        <h2>{backtestResults.metrics.totalTransactions}</h2>
        <h5>$ {formatNumber(backtestResults.metrics.totalCommissions)} fees</h5>
      </div>
      <div className="pt-2">
        <h4>Avg. transactions</h4>
        <div className="flex gap-8">
          <div>
            <h5>Per Month</h5>
            <h3>{backtestResults.metrics.avgTransactionsPerMonth}</h3>
          </div>
          <div>
            <h5>Per Year</h5>
            <h3>{backtestResults.metrics.avgTransactionsPerYear}</h3>
          </div>
        </div>
      </div>
    </GenericCard>
  );
};

TotalTransactions.propTypes = { backtestResults: PropTypes.object };

export default TotalTransactions;
