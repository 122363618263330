import React, { useEffect, useMemo, useState } from "react";
import { apiCreateNewModel } from "api/apiAiModels";

import { App, Form } from "antd";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import SelectedSectorsChart from "components/ModelCreation/SelectedSectorsChart";
import { apiCheckAiSymbol } from "api/apiAiResults";
import CreateModelForm from "./CreateModelForm";
import BuyTotalStocksCard from "./BuyTotalStocksCard";
import ModelCreationStatus from "./ModelCreationStatus";
import { apiEvaluateBuySellPolarity } from "api/apiPredictions";

const ModelCreation = () => {
  const symbolsList = useSelector((state) => state.main.symbolsList);
  const symbolsDict = useSelector((state) => state.main.symbolsDict);
  const { message } = App.useApp();

  const [form] = Form.useForm();
  const [isPending, setIsPending] = useState(false);
  const [requestStatus, setRequestStatus] = useState("");
  const [evaluation, setEvaluation] = useState("");
  const [totalCount, setTotalCount] = useState(null);
  const [buyTotalCount, setBuyTotalCount] = useState(null);
  const [isPredicting, setIsPredicting] = useState(false);
  const [modelData, setModelData] = useState(null);

  const [buyTotalResults, setBuyTotalResults] = useState(null);

  const symbolsGroupedInSectored = useMemo(() => {
    const sectors = symbolsList.reduce((acc, item) => {
      const symbol = {
        label: `${item.name} - (${item.ticker}) ${
          buyTotalResults && buyTotalResults[item.ticker]?.polarity
            ? buyTotalResults[item.ticker]?.polarity + "%"
            : ""
        }`,
        value: item.ticker,
      };
      acc[item.sector.name] = acc[item.sector.name]
        ? [...acc[item.sector.name], symbol]
        : [symbol];
      return acc;
    }, {});

    const result = Object.entries(sectors).map((sector) => {
      return {
        label: sector[0],
        options: sector[1],
      };
    });
    return result;
  }, [symbolsList, buyTotalResults]);

  const evaluateBuySellPolarity = async () => {
    const { dates, days, percToGain } = form.getFieldsValue();
    const response = await apiEvaluateBuySellPolarity({
      startDate: dates[0].format("YYYY-MM-DD"),
      endDate: dates[1].format("YYYY-MM-DD"),
      days,
      percToGain,
    });

    setBuyTotalResults(response);
  };

  const evaluateTotalPolarity = () => {
    const { leads } = form.getFieldsValue();
    if (!buyTotalResults || !leads) return;
    const results = leads.reduce(
      (acc, value) => {
        acc.allTrue += buyTotalResults[value]?.trueCount || 0;
        acc.allTotal += buyTotalResults[value]?.total || 0;
        return acc;
      },
      { allTrue: 0, allTotal: 0 }
    );
    const allTruePercentage = (results.allTrue / results.allTotal) * 100;
    setEvaluation(allTruePercentage);
    setTotalCount(results.allTotal);
    setBuyTotalCount(results.allTrue);
  };

  const handleCreateModel = async (values) => {
    if (!values.indicators) {
      return;
    }
    const { leads, dates, name, percToGain, days, modelType } = values;
    setIsPending(true);
    setRequestStatus("");
    try {
      const response = await apiCreateNewModel({
        leads,
        name,
        percToGain,
        days,
        indicators: values.indicators,
        startDate: dates[0].format("YYYY-MM-DD"),
        endDate: dates[1].format("YYYY-MM-DD"),
        modelType,
      });
      setIsPending(false);
      if (response.error) {
        setRequestStatus("error");
        message.error("Error creating model!");
        return;
      }
      setModelData(response);
      setRequestStatus(response._id);
      message.success("Model created successfully!");
    } catch (err) {
      setRequestStatus("error");
    }
  };

  const predictAllModelHistory = async () => {
    setIsPredicting(true);
    const values = form.getFieldsValue();
    const formData = {
      symbol: symbolsList.map((item) => item.ticker),
      modelId: requestStatus,
      startDate: dayjs("2010-01-01").format("YYYY-MM-DD"),
      endDate: dayjs().add(1, "d").format("YYYY-MM-DD"),
      save_predictions: true,
      percToGain: values.percToGain,
      days: values.days,
      indicators: values.indicators,
    };
    try {
      await apiCheckAiSymbol(formData);
      setIsPredicting(false);
      setRequestStatus("success");
      message.success("Model Predicted successfully!");
      // form.resetFields();
    } catch (err) {}
  };

  useEffect(() => {
    if (buyTotalResults) {
      evaluateTotalPolarity();
    }
  }, [buyTotalResults]);

  useEffect(() => {
    document.title = "Create Model | Dindicator Dashboard";
  }, []);

  return (
    <>
      <h1 className="pb-3 ps-1">Create New Model</h1>
      <div className="cards-wrapper">
        <CreateModelForm
          form={form}
          isPending={isPending}
          isPredicting={isPredicting}
          handleCreateModel={handleCreateModel}
          symbolsGroupedInSectored={symbolsGroupedInSectored}
          evaluateTotalPolarity={evaluateTotalPolarity}
          evaluateBuySellPolarity={evaluateBuySellPolarity}
        />
      </div>
      <div className="cards-wrapper">
        <ModelCreationStatus
          requestStatus={requestStatus}
          setRequestStatus={setRequestStatus}
          isPredicting={isPredicting}
          predictAllModelHistory={predictAllModelHistory}
          modelData={modelData}
          setModelData={setModelData}
        />

        <SelectedSectorsChart symbolsDict={symbolsDict} form={form} />

        <BuyTotalStocksCard
          evaluation={evaluation}
          totalCount={totalCount}
          buyTotalCount={buyTotalCount}
          form={form}
        />
      </div>
    </>
  );
};

export default ModelCreation;
