import React, { useEffect } from "react";

import { Tabs } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { paths } from "utils/constants";
import { useSelector } from "react-redux";

const AdminDashboard = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isAdmin = useSelector((state) => state.main.user.role === "admin");

  const menuItems = [
    {
      label: "Symbol management",
      key: paths.symbolsManagement,
    },
    {
      label: "System Control",
      key: paths.systemControl,
    },
  ];
  if (isAdmin) {
    menuItems.push(
      {
        label: "Users management",
        key: paths.usersManagement,
      },
      {
        label: "Yahoo Finance Price Compare",
        key: paths.yahooFinancePriceCompare,
      },
      {
        label: "Save Predictions",
        key: paths.savePredictions,
      },
      {
        label: "Recent Tasks",
        key: paths.recentTasks,
      }
    );
  }

  const handleChangeTab = (path) => {
    navigate(path);
  };

  const navigateToFirstAvailablePage = () => {
    for (let obj of menuItems) {
      navigate(obj.key, { replace: true });
      break;
    }
  };

  useEffect(() => {
    if (pathname === paths.admin) {
      navigateToFirstAvailablePage();
    }
  }, [pathname]);

  return (
    <>
      <Tabs
        defaultActiveKey="1"
        items={menuItems}
        activeKey={pathname}
        onChange={handleChangeTab}
      />
      <Outlet />
    </>
  );
};

export default AdminDashboard;
