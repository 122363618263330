import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import GenericCard from "components/UI/GenericCard/GenericCard";
import { App, Button, Col, Descriptions, Divider, Row } from "antd";
import dayjs from "dayjs";
import {
  apiDeleteUser,
  apiDisableAutomation,
  apiEnableAutomation,
  apiResetUserPassword,
  apiRevokeUserToken,
  apiToggleAutomationHosting,
  apiToggleBanUser,
} from "api/apiAdmin";
import {
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";

const UserDetails = (props) => {
  const { selectedUser, setSelectedUser } = props;
  const { message, modal } = App.useApp();

  const deleteUser = async () => {
    try {
      await apiDeleteUser(selectedUser._id);
      setSelectedUser(null);
      message.success("User has been deleted successfully");
    } catch (err) {
      console.error(err);
    }
  };

  const onDeleteUser = async () => {
    modal.confirm({
      title: "Are you sure you want to delete this user?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteUser();
      },
      onCancel() {},
    });
  };

  const onToggleBanUser = async () => {
    const res = await apiToggleBanUser({
      userId: selectedUser._id,
      enabled: !selectedUser.enabled,
    });
    setSelectedUser((prevState) => {
      return { ...prevState, enabled: res };
    });
  };

  const onRevokeUserToken = async () => {
    const uSession = await apiRevokeUserToken({
      userId: selectedUser._id,
    });

    setSelectedUser((prevState) => {
      return { ...prevState, uSession };
    });
  };

  const onResetUserPassword = async () => {
    try {
      await apiResetUserPassword({ _id: selectedUser._id });
      message.success("User password has been reset successfully");
    } catch (err) {
      console.error(err);
    }
  };

  const onEnableAutomation = async () => {
    try {
      const updatedUser = await apiEnableAutomation({
        userId: selectedUser._id,
      });
      setSelectedUser(updatedUser);

      message.success("Automation has been enabled successfully");
    } catch (err) {
      message.error(err.response?.data?.message || err.message);
    }
  };

  const onDisableAutomation = async () => {
    try {
      const updatedUser = await apiDisableAutomation({
        userId: selectedUser._id,
      });
      setSelectedUser(updatedUser);

      message.success("Automation has been disabled successfully");
    } catch (err) {
      message.error(err.response?.data?.message || err.message);
    }
  };

  const onToggleAutomationHosting = async () => {
    try {
      const updatedUser = await apiToggleAutomationHosting({
        userId: selectedUser._id,
      });
      setSelectedUser(updatedUser);

      message.success(
        `Automation hosting has been ${
          updatedUser.automation.hosting ? "enabled" : "disabled"
        } successfully`
      );
    } catch (err) {
      message.error(err.response?.data?.message || err.message);
    }
  };

  const fields = useMemo(() => {
    return [
      {
        label: "Username",
        value: selectedUser.username,
      },
      {
        label: "Name",
        value: selectedUser.firstName,
      },
      {
        label: "Last name",
        value: selectedUser.lastName,
      },
      {
        label: "Role",
        value: selectedUser.role,
      },
      {
        label: "Enabled",
        value: selectedUser.enabled ? (
          <CheckOutlined style={{ fontSize: 22, color: "#00c900" }} />
        ) : (
          <CloseOutlined style={{ fontSize: 22, color: "#ff0000" }} />
        ),
      },
      {
        label: "Last login",
        value: dayjs(selectedUser.lastLogin).format("HH:mm DD/MM/YY"),
      },
      {
        label: "Token unique session",
        value: dayjs(selectedUser.uSession).format("HH:mm DD/MM/YY"),
      },
      {
        label: "Created",
        value: dayjs(selectedUser.createdAt).format("HH:mm DD/MM/YY"),
      },
      {
        label: "Automation permissions",
        value: selectedUser.automation.enabled ? (
          <CheckOutlined style={{ fontSize: 22, color: "#00c900" }} />
        ) : (
          <CloseOutlined style={{ fontSize: 22, color: "#ff0000" }} />
        ),
      },
      {
        label: "Automation hosting",
        value: selectedUser.automation?.hosting ? (
          <CheckOutlined style={{ fontSize: 22, color: "#00c900" }} />
        ) : (
          <CloseOutlined style={{ fontSize: 22, color: "#ff0000" }} />
        ),
      },
    ];
  }, [selectedUser]);

  useEffect(() => {
    // set title with username
    document.title = `${selectedUser.firstName || "Not registered"} ${
      selectedUser.lastName || ""
    } | User Details`;
  }, [selectedUser]);

  return (
    <GenericCard className="table-card">
      <Descriptions
        title="User Details"
        column={{
          xxl: 4,
          xl: 3,
          lg: 3,
          md: 3,
          sm: 2,
          xs: 1,
        }}
      >
        {fields.map((field) => (
          <Descriptions.Item label={field.label}>
            {field.value}
          </Descriptions.Item>
        ))}
      </Descriptions>
      {selectedUser.firstName && (
        <>
          <div className="flex gap-3 pt-4 flex-wrap">
            {process.env.NODE_ENV === "development" && (
              <Col>
                <Button
                  type="primary"
                  danger={selectedUser.enabled}
                  onClick={onDeleteUser}
                >
                  Delete user
                </Button>
              </Col>
            )}
            <Button
              type="primary"
              danger={selectedUser.enabled}
              onClick={onToggleBanUser}
            >
              {selectedUser.enabled ? "Ban user" : "Enable user"}
            </Button>
            <Button onClick={onRevokeUserToken}>Revoke token</Button>
            <Button onClick={onResetUserPassword}>Reset password</Button>
          </div>

          <Divider orientation="left">Automation Permissions</Divider>

          <div className="flex gap-3 pt-4 flex-wrap">
            <Button
              onClick={
                selectedUser.automation.enabled
                  ? onDisableAutomation
                  : onEnableAutomation
              }
            >
              {selectedUser.automation.enabled
                ? "Disable Automation"
                : "Enable Automation"}
            </Button>

            <Button onClick={onToggleAutomationHosting}>
              {selectedUser.automation.hosting
                ? "Disable Automation Hosting"
                : "Enable Automation Hosting"}
            </Button>
          </div>
        </>
      )}
    </GenericCard>
  );
};

UserDetails.propTypes = {
  selectedUser: PropTypes.object,
  setSelectedUser: PropTypes.func,
};

export default UserDetails;
