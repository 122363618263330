import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Segmented,
  Switch,
} from "antd";
import { apiGetStrategyTabs } from "api/apiStrategyTabs";
import CheckableTag from "antd/es/tag/CheckableTag";
import { useSelector } from "react-redux";
import { selectAutomationAllowed } from "store/slices/main";

const CreateStrategy = (props) => {
  const { createNewStrategy } = props;
  const [form] = Form.useForm();
  const automated = Form.useWatch("automated", form);
  const trailingTakeProfitEnabled = Form.useWatch(
    "trailingTakeProfitEnabled",
    form
  );
  const isRealStrategy = !Form.useWatch("isDemo", form);
  const automationAllowed = useSelector(selectAutomationAllowed);

  const [isOpen, setIsOpen] = useState(false);
  const [strategyTabs, setStrategyTabs] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  // const isDemoStrategy = Form.useWatch("isDemo", form);

  const handleCreateNewStrategy = async (values) => {
    await createNewStrategy({ ...values, strategyTabs: selectedTags });
    setIsOpen(false);
  };

  const getUserStrategyTabs = async () => {
    const response = await apiGetStrategyTabs();
    setStrategyTabs(response);
  };

  const handleChangeStrategyTabs = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
  };

  useEffect(() => {
    getUserStrategyTabs();
  }, []);

  return (
    <>
      <Button type="primary" onClick={() => setIsOpen(true)}>
        Create Strategy
      </Button>
      <Modal
        title="New Strategy"
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        footer={null}
        width={800}
        destroyOnClose={true}
      >
        <Form
          form={form}
          layout="vertical"
          name="new_strategy"
          onFinish={handleCreateNewStrategy}
          initialValues={{ isDemo: true, enabled: true, automated: false }}
          preserve={false}
        >
          <Form.Item
            name="name"
            label="Strategy Name"
            rules={[
              {
                required: true,
                message: "Strategy name is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Row gutter={16}>
            <Col>
              <Form.Item name="isDemo" label="Account">
                <Segmented
                  options={[
                    { label: "Demo account", value: true },
                    { label: "Real account", value: false },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Enabled" valuePropName="checked" name="enabled">
                <Switch />
              </Form.Item>
            </Col>
            {automationAllowed && (
              <Col>
                <Form.Item
                  label="Automated Trading"
                  valuePropName="automated"
                  name="automated"
                >
                  <Switch />
                </Form.Item>
              </Col>
            )}
          </Row>

          {automationAllowed && automated && isRealStrategy && (
            <>
              <Divider orientation="left">Automated Trading Settings</Divider>
              <Row gutter={16}>
                <Col>
                  <Form.Item
                    name="buyMaxPercentDiff"
                    label="Max % difference from buy point"
                    initialValue={0.5}
                  >
                    <InputNumber style={{ width: "100%" }} prefix="%" />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="orderUpdateInterval"
                    label="Price update interval"
                    initialValue={15}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      addonAfter="Seconds"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider orientation="left">Trailing Stop Loss</Divider>
              <Col>
                <Form.Item
                  label="Enabled"
                  valuePropName="checked"
                  name="trailingTakeProfitEnabled"
                >
                  <Switch />
                </Form.Item>
              </Col>
              {trailingTakeProfitEnabled && (
                <Row gutter={16}>
                  <Col>
                    <Form.Item
                      name="trailingTakeProfitActivationThreshold"
                      label="Activation Threshold %"
                      initialValue={6.5}
                    >
                      <InputNumber style={{ width: "100%" }} addonAfter="%" />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name="trailingTakeProfitDeclineFromPeakLimit"
                      label="Trailing Take Profit Decline From Peak Limit"
                      initialValue={1}
                    >
                      <InputNumber style={{ width: "100%" }} addonAfter="%" />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Divider />
            </>
          )}

          {strategyTabs.length > 0 && (
            <Form.Item label="Strategy Tabs">
              {strategyTabs.map((tag) => {
                if (tag.key === "all") return null;
                return (
                  <CheckableTag
                    key={tag._id}
                    checked={selectedTags.includes(tag._id)}
                    onChange={(checked) =>
                      handleChangeStrategyTabs(tag._id, checked)
                    }
                  >
                    {tag.name}
                  </CheckableTag>
                );
              })}
            </Form.Item>
          )}

          <Form.Item shouldUpdate style={{ paddingBlockStart: 10 }}>
            {() => (
              <Button type="primary" htmlType="submit">
                Play!
              </Button>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

CreateStrategy.propTypes = {
  createNewStrategy: PropTypes.func,
};

export default CreateStrategy;
