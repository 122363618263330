import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import GenericCard from "components/UI/GenericCard/GenericCard";
import TotalTransactions from "components/Cards/TotalTransactions";
import BuyAndHoldReturn from "components/Cards/BuyAndHoldReturn";
import TotalPositionsClosedWithProfit from "components/Cards/TotalPositionsClosedWithProfit";
import MinMaxPerTransactionCard from "components/Cards/MinMaxPerTransactionCard";
import AverageProfitPerTransaction from "components/Cards/AverageProfitPerTransaction";
import AiBacktestTable from "components/AiMagician/AiBacktestTable";
import TVChartContainer from "components/TVChartContainer/TVChartContainer";
import PendingDeal from "./Cards/PendingDeal";
import StrategyOpenOrders from "./Cards/StrategyOpenOrders";
import PendingSell from "./Cards/PendingSell";

import StrategyDetails from "./Cards/StrategyDetails";
import AccProfitChart from "components/AiMagician/Charts/AccProfitChart";
import StrategyNotes from "./Cards/StrategyNotes/StrategyNotes";
import { apiFetchPredictionsMultipleModels } from "api/apiPredictions";
import dayjs from "dayjs";
import PredictionsTable from "components/AiMagician/PredictionsTable";
import StrategyActivityCard from "./Cards/StrategyActivity/StrategyActivityCard";

const LoadedStrategy = (props) => {
  const { selectedStrategy, getUserStrategies, strategyTabs } = props;
  const [predictions, setPredictions] = useState(null);
  const [visibleRange, setVisibleRange] = useState({ from: 0, to: 0 });

  const setTransactionVisibleRange = (openDate, closeDate) => {
    const from = Math.floor(+new Date(openDate)) / 1000;
    const to = Math.floor(+new Date(closeDate)) / 1000;
    setVisibleRange({ from, to });
  };

  const resetVisibleRange = () => {
    setVisibleRange({ from: 0, to: 0 });
  };

  const fetchPredictions = async () => {
    const response = await apiFetchPredictionsMultipleModels({
      symbol: selectedStrategy.symbol,
      models: selectedStrategy.models.map((model) => model._id),
      from: dayjs().subtract(1, "year").format("YYYY-MM-DD"),
      to: dayjs().format("YYYY-MM-DD"),
    });
    setPredictions(response);
  };

  useEffect(() => {
    fetchPredictions();
  }, []);

  return (
    <div className="cards-wrapper">
      <TotalTransactions backtestResults={selectedStrategy} />
      <BuyAndHoldReturn backtestResults={selectedStrategy} />
      <TotalPositionsClosedWithProfit backtestResults={selectedStrategy} />
      <MinMaxPerTransactionCard backtestResults={selectedStrategy} />
      <AverageProfitPerTransaction backtestResults={selectedStrategy} />

      {selectedStrategy.pendingBuys?.length > 0 && (
        <PendingDeal
          pendingDeal={selectedStrategy.pendingBuys}
          getUserStrategies={getUserStrategies}
        />
      )}

      {selectedStrategy.pendingSells?.length > 0 && (
        <PendingSell
          pendingSells={selectedStrategy.pendingSells}
          getUserStrategies={getUserStrategies}
        />
      )}

      {selectedStrategy.openPositions?.length > 0 && (
        <GenericCard title="Open Orders" className="table-card" maxHeight={610}>
          <StrategyOpenOrders
            openOrders={selectedStrategy.openPositions}
            profitTargetPercent={selectedStrategy.params.takeProfitPercent}
            getUserStrategies={getUserStrategies}
          />
        </GenericCard>
      )}

      {selectedStrategy.transactions.length > 0 && (
        <AiBacktestTable
          transactions={selectedStrategy.transactions}
          setTransactionVisibleRange={setTransactionVisibleRange}
        />
      )}
      <GenericCard
        title="Trading View"
        className="table-card"
        isCardOpenByDefault={false}
      >
        <TVChartContainer
          symbol={selectedStrategy.symbol}
          transactions={selectedStrategy.transactions}
          openPositions={selectedStrategy.openPositions}
          visibleRange={visibleRange}
          resetVisibleRange={resetVisibleRange}
        />
      </GenericCard>
      <AccProfitChart transactions={selectedStrategy.transactions} />

      {predictions && <PredictionsTable data={predictions} isOpen={false} />}

      <StrategyDetails
        strategy={selectedStrategy}
        getUserStrategies={getUserStrategies}
        strategyTabs={strategyTabs}
      />

      <StrategyActivityCard selectedStrategy={selectedStrategy} />

      <StrategyNotes
        strategy={selectedStrategy}
        getUserStrategies={getUserStrategies}
      />
    </div>
  );
};

LoadedStrategy.propTypes = {
  selectedStrategy: PropTypes.object,
  getUserStrategies: PropTypes.func,
  strategyTabs: PropTypes.array,
};

export default LoadedStrategy;
