import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment/moment";
import { App, Button, List, Progress } from "antd";

import { ExclamationCircleFilled } from "@ant-design/icons";
import { DeleteOutlined } from "@ant-design/icons";
import ProfileAvatar from "components/UI/ProfileAvatar";

import { apiDeleteModel } from "api/apiAiModels";
import GenericCard from "components/UI/GenericCard/GenericCard";
import SavePredictionsButton from "./SavePredictionsButton";

const SavePredictionsChooseModel = (props) => {
  const { modelsList, getModelsList, handleCheckAiSymbol } = props;
  const { message, modal } = App.useApp();
  const [errorMessage, setErrorMessage] = useState("");
  const [isSearchAllPending, setIsSearchAllPending] = useState(false);
  const [progress, setProgress] = useState(null);
  const [modelName, setModelName] = useState("");

  const deleteModelById = async (modelId) => {
    try {
      await apiDeleteModel(modelId);
      getModelsList();
      message.success("Model deleted successfully");
    } catch (err) {
      message.error(err.response.data.message);
    }
  };

  const showDeleteConfirm = (modelId) => {
    modal.confirm({
      title: "Are you sure you want to delete this model?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteModelById(modelId);
      },
      onCancel() {},
    });
  };

  const onSearchAllSuccess = () => {
    setIsSearchAllPending(false);
    setModelName("");
  };

  const onSearchAllFailure = (message = "Error") => {
    setIsSearchAllPending(false);
    setErrorMessage(message);
  };

  const onSearchAllProgressUpdate = (progressPercent, name) => {
    setProgress(progressPercent);
    if (name) {
      setModelName(name);
    }
  };

  const handleSearchAllModels = () => {
    setIsSearchAllPending(true);
    setProgress(0);
    setErrorMessage("");

    handleCheckAiSymbol(
      null,
      onSearchAllSuccess,
      onSearchAllFailure,
      onSearchAllProgressUpdate
    );
  };

  return (
    <GenericCard title="Choose models">
      <div>
        <div className="results-table-buttons">
          <Button disabled={isSearchAllPending} onClick={handleSearchAllModels}>
            Search all models
          </Button>
        </div>

        {progress !== null ? (
          <div>
            <div
              style={{
                width: 170,
              }}
            >
              <Progress
                percent={progress}
                status={`${isSearchAllPending ? "active" : ""}${
                  errorMessage ? "exception" : ""
                }`}
              />
            </div>
            {modelName && (
              <p
                style={{ color: "#ffffff" }}
                className="pb-2"
              >{`Predicting: ${modelName}`}</p>
            )}
          </div>
        ) : (
          <></>
        )}
        <List
          size="small"
          bordered
          dataSource={modelsList}
          renderItem={(item) => (
            <List.Item
              actions={[
                item.usedByStrategies === 0 && (
                  <Button
                    icon={<DeleteOutlined />}
                    onClick={() => showDeleteConfirm(item._id)}
                    danger
                    ghost
                  />
                ),
                <SavePredictionsButton
                  model={item}
                  handleCheckAiSymbol={handleCheckAiSymbol}
                  isDisabled={isSearchAllPending}
                />,
              ]}
            >
              <List.Item.Meta
                avatar={<ProfileAvatar user={item?.creator} />}
                title={item.name}
                description={
                  <>
                    <p>{item.leads.map((item) => `${item}, `)}</p>
                    <p>
                      Trained data:{" "}
                      <span style={{ color: "#ffffff" }}>
                        {moment(item.results?.start_date).format("DD/MM/YY")}
                        {" - "}
                        {moment(item.results?.end_date).format("DD/MM/YY")}
                      </span>
                    </p>
                    <p>
                      Profit target:{" "}
                      <span style={{ color: "#ffffff" }}>
                        {((item.results?.perc_to_gain - 1) * 100).toFixed()}%
                      </span>
                    </p>
                    <p>
                      Days target:{" "}
                      <span style={{ color: "#ffffff" }}>
                        {item.results?.days}
                      </span>
                    </p>
                    <p>
                      Buy/total:{" "}
                      <span style={{ color: "#ffffff" }}>
                        {(item.results?.buy_from_total * 100).toFixed(1)}%
                      </span>
                    </p>
                    <p>
                      Accuarcy:{" "}
                      <span style={{ color: "#ffffff" }}>
                        {(item.results?.accuracy * 100).toFixed(1)}%
                      </span>
                    </p>
                    {item.usedByStrategies > 0 && (
                      <p>
                        <span style={{ color: "#ffffff" }}>
                          Used by {item.usedByStrategies} strategies
                        </span>
                      </p>
                    )}

                    <p>
                      {item?.creator
                        ? `Created by ${item.creator.firstName} ${item?.creator?.lastName}, `
                        : ""}
                      {moment(item.date).format("HH:mm DD/MM/YY")}
                    </p>
                  </>
                }
              />
            </List.Item>
          )}
        />
      </div>
    </GenericCard>
  );
};

SavePredictionsChooseModel.propTypes = {
  setChoosenModel: PropTypes.func,
  choosenModel: PropTypes.object,
};

export default SavePredictionsChooseModel;
