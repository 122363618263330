import React from "react";
import PropTypes from "prop-types";
import GenericCard from "../UI/GenericCard/GenericCard";
import DashedTooltip from "components/UI/DashedTooltip";

const RiskMeasures = (props) => {
  const { backtestResults } = props;

  return (
    <GenericCard title="Risk Measures">
      <div className="flex gap-4">
        <div>
          <h5>
            <DashedTooltip
              text={`The Sharpe Ratio is widely used by portfolio managers and individual traders to show how much risk was taken to achieve specific returns. The formula for the Sharpe ratio is SR = (MR - RFR) / SD, where MR is the average return for a period (monthly for a trading period of 3 or more months or daily for a trading period of 3 or more days), and RFR is the risk-free rate of return (by default, 2% annually. Can be changed with the "risk_free_rate" parameter of the "strategy()" function). SD is the standard deviation of returns. Thus, this formula yields a value that could be loosely defined as return per unit risked if we accept the premise that variability is risk. The higher Sharpe ratio, the smoother the equity curve. Having a smooth equity curve is an important objective for many traders.`}
            >
              Sharpe Ratio
            </DashedTooltip>
          </h5>
          <h3>{backtestResults.metrics.sharpeRatio}</h3>
        </div>
        <div>
          <h5>Sortino Ratio</h5>
          <h3>{backtestResults.metrics.sortinoRatio}</h3>
        </div>
      </div>
      <div className="flex gap-2 pt-2">
        <div className="">
          <h5>Standard Deviation</h5>
          <h3>{backtestResults.metrics.standardDeviation}%</h3>
        </div>
        <div className="">
          <h5>Upside SD</h5>
          <h3>{backtestResults.metrics.upsideDeviation.toLocaleString()}%</h3>
        </div>
        <div>
          <h5>Downside SD</h5>
          <h3>{backtestResults.metrics.downsideDeviation.toLocaleString()}%</h3>
        </div>
      </div>
    </GenericCard>
  );
};
RiskMeasures.propTypes = {
  backtestResults: PropTypes.object,
};

export default RiskMeasures;
