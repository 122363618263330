import axios from "utils/axios";
import { routes } from "api/routes";

export const apiGetLogsByUser = async (options) => {
  const { data } = await axios.post(routes.getLogsByUser, options);
  return data;
};

export const apiSearchUser = async (params) => {
  const { data } = await axios.get(routes.searchUser, { params });
  return data;
};

export const apiDeleteUser = async (userId) => {
  const { data } = await axios.delete(routes.deleteUser, {
    params: { userId },
  });
  return data;
};

export const apiResetUserPassword = async (formData) => {
  const { data } = await axios.post(routes.resetUserPassword, formData);
  return data;
};

export const apiToggleBanUser = async (formData) => {
  const { data } = await axios.post(routes.toggleBanUser, formData);
  return data;
};

export const apiRevokeUserToken = async (formData) => {
  const { data } = await axios.post(routes.revokeToken, formData);
  return data;
};

export const apiInviteUser = async (formData) => {
  const { data } = await axios.post(routes.inviteUser, formData);
  return data;
};

export const apiGetUserDetailsById = async (userId) => {
  const { data } = await axios.get(routes.getUserDetailsById, {
    params: { userId },
  });
  return data;
};

export const apiGetOnlineUserActivity = async (userId) => {
  const { data } = await axios.get(routes.getOnlineUserActvity, {
    params: { userId },
  });
  return data;
};

export const apiSearchTrades = async (formData) => {
  const { data } = await axios.post(routes.searchTrades, formData);
  return data;
};

export const apiEditTrade = async (formData) => {
  const { data } = await axios.put(routes.editTrade, formData);
  return data;
};

export const apiDeleteTrade = async (tradeId) => {
  const { data } = await axios.delete(routes.deleteTrade, {
    params: { tradeId },
  });
  return data;
};

/**
 * Get server connections list to detect foreign connections
 */
export const apiGetServerConnectionsList = async () => {
  const { data } = await axios.get(routes.getConnectionToServer);
  return data;
};

export const apiEnableAutomation = async (formData) => {
  const { data } = await axios.put(routes.enableAutomation, formData);
  return data;
};

export const apiDisableAutomation = async (formData) => {
  const { data } = await axios.put(routes.disableAutomation, formData);
  return data;
};

export const apiToggleAutomationHosting = async (formData) => {
  const { data } = await axios.put(routes.toggleAutomationHosting, formData);
  return data;
};

export const apiFetchRecentTasks = async () => {
  const { data } = await axios.get(routes.fetchRecentTasks);
  return data;
};

export const apiFetchTaskLogs = async (taskId) => {
  const { data } = await axios.get(routes.fetchTaskLogs, {
    params: { taskId },
  });
  return data;
};

export const apiGetAvailableTasks = async () => {
  const { data } = await axios.get(routes.getAvailableTasks);
  return data;
};

export const apiTriggerAutomationTask = async (taskType) => {
  const { data } = await axios.post(routes.triggerAutomationTask, { taskType });
  return data;
};
