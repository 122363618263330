import React from "react";
import { useNavigate } from "react-router";
import { paths } from "utils/constants";
import useMediaQuery from "hooks/useMediaQuery";

import { useDispatch, useSelector } from "react-redux";
import { selectAutomationAllowed, toggleDrawer } from "store/slices/main";

import { CloseCircleOutlined } from "@ant-design/icons";
import TrendLogo from "assets/trending.png";
import NavbarButton from "components/Drawer/NavbarButton";
import ProtectedContent from "components/UI/ProtectedContent";

import "./Drawer.scss";

const Drawer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDrawerOpen = useSelector((state) => state.main.isDrawerOpen);
  const automationAllowed = useSelector(selectAutomationAllowed);

  const isMobile = useMediaQuery("(max-width: 700px)");

  const onNavigateHome = () => navigate(paths.home);

  const onToggleDrawer = () => dispatch(toggleDrawer());

  return (
    <div className={`${isDrawerOpen ? "drawer" : "drawer drawer-closed"}`}>
      {isMobile && (
        <CloseCircleOutlined
          className="close-button"
          onClick={onToggleDrawer}
        />
      )}
      <div className="logo-section" onClick={onNavigateHome}>
        <div className="logo-wrapper">
          <img src={TrendLogo} alt="" className="logo-icon" />
          <h1>Dindicator</h1>
        </div>
      </div>
      <div className="buttons-wrapper">
        <NavbarButton path={paths.home} text="Home" />
        <ProtectedContent requiredPermissions={["manager"]}>
          <NavbarButton path={paths.createModel} text="Create Model" />
        </ProtectedContent>
        <NavbarButton path={paths.ai} text="AI Backtests" />
        <NavbarButton path={paths.leaderboards} text="Leaderboards" />
        <NavbarButton path={paths.strategies} text="Strategies" />
        <NavbarButton path={paths.openPositions} text="Open Positions" />
        <NavbarButton path={paths.tradesHistory} text="Trades History" />

        {automationAllowed && (
          <NavbarButton path={paths.ordersStatus} text="Orders Status" />
        )}

        <ProtectedContent requiredPermissions={["manager"]}>
          <NavbarButton path={paths.admin} text="Admin panel" />
        </ProtectedContent>
      </div>
    </div>
  );
};

export default Drawer;
