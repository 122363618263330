import React from "react";
import PropTypes from "prop-types";
import GenericCard from "components/UI/GenericCard/GenericCard";
import { returnUpDownClassname } from "utils/helpers";
import DashedTooltip from "components/UI/DashedTooltip";

const RealizedPnl = (props) => {
  const { closedPositions, strategies } = props;

  const pnl = closedPositions.reduce(
    (acc, item) => acc + item.netProfit.value,
    0
  );
  const twr = strategies
    ? strategies.reduce((acc, item) => {
        if (acc === 0) {
          return 1 + item.metrics.pnlPercent / 100;
        }

        if (item.metrics.pnlPercent === 0) {
          return acc;
        }
        return acc * (1 + item.metrics.pnlPercent / 100);
      }, 0)
    : null;

  const twr2 = closedPositions.reduce((acc, item) => {
    if (acc === 0) {
      return 1 + item.netProfit.perc / 100;
    }

    if (item.netProfit.perc === 0) {
      return acc;
    }
    return acc * (1 + item.netProfit.perc / 100);
  }, 0);

  const twrPercentage = twr ? ((twr - 1) * 100).toFixed(2) : null;
  const twrPercentage2 = twr2 ? ((twr2 - 1) * 100).toFixed(2) : null;

  return (
    <GenericCard title="Realized PNL" tooltipText="Total closed position PNL">
      <h2 className={returnUpDownClassname(pnl)}>${pnl.toLocaleString()}</h2>
      {twrPercentage && (
        <>
          <h3>
            <DashedTooltip text="This measures the overall performance of the strategy from its initial balance to its end balance. It accounts for any unused capital, reflecting how efficiently the strategy uses available funds over time.">
              Strategy Growth TWR
            </DashedTooltip>
          </h3>
          <p className={returnUpDownClassname(twrPercentage)}>
            {twrPercentage}%
          </p>
        </>
      )}
      {twrPercentage2 && (
        <>
          <h3>
            <DashedTooltip text="This focuses purely on the profitability of individual trades, ignoring any uninvested capital. It reflects the performance of each trade, assuming all available capital was fully invested.">
              Trade Performance TWR
            </DashedTooltip>
          </h3>
          <p className={returnUpDownClassname(twrPercentage2)}>
            {twrPercentage2}%
          </p>
        </>
      )}
    </GenericCard>
  );
};

RealizedPnl.propTypes = {
  closedPositions: PropTypes.array,
  strategies: PropTypes.array,
};

export default RealizedPnl;
