import React, { useEffect, useState } from "react";
import GenericCard from "components/UI/GenericCard/GenericCard";
import ActiveLight from "components/UI/ActiveLight/ActiveLight";
import { apiGetTwsStatus, apiStartTws, apiStopTws } from "api/apiTws";
import { Button } from "antd";

const TwsStatus = () => {
  const [twsStatus, setTwsStatus] = useState(false);

  const getTwsStatus = async () => {
    try {
      const isActive = await apiGetTwsStatus();
      setTwsStatus(isActive);
    } catch (err) {}
  };

  const onStartTws = async () => {
    try {
      await apiStartTws();
      setTimeout(() => {
        getTwsStatus();
      }, 500);
    } catch (err) {}
  };

  const onStopTws = async () => {
    try {
      const isActive = await apiStopTws();
      setTwsStatus(isActive);
    } catch (err) {}
  };

  useEffect(() => {
    getTwsStatus();
  }, []);

  return (
    <GenericCard title="TWS Status">
      {twsStatus ? (
        <div>
          <ActiveLight />

          <Button className="mt-4" onClick={onStopTws}>
            Stop TWS
          </Button>
        </div>
      ) : (
        <div>
          <h3 className="red">Off</h3>
          <Button className="mt-4" onClick={onStartTws}>
            Start TWS
          </Button>
        </div>
      )}
    </GenericCard>
  );
};

export default TwsStatus;
