import axios from "utils/axios";
import { routes } from "api/routes";

export const apiCreateNewStrategy = async (strategyData) => {
  const { data } = await axios.post(routes.createNewStrategy, strategyData);
  return data;
};

export const apiToggleStrategyActive = async (strategyData) => {
  const { data } = await axios.put(routes.toggleStrategyActive, strategyData);
  return data;
};

export const apiToggleStrategyAccountType = async (strategyData) => {
  const { data } = await axios.put(
    routes.toggleStrategyAccountType,
    strategyData
  );
  return data;
};

export const apiGetUserStrategies = async (formData) => {
  const { data } = await axios.get(routes.getUserStrategies, {
    params: formData,
  });
  return data;
};

export const apiRecountStrategyTrend = async (formData) => {
  const { data } = await axios.post(routes.recountStrategyTrend, formData);
  return data;
};

export const apiConfirmStrategyTrade = async (formData) => {
  const { data } = await axios.post(routes.confirmStrategyTrade, formData);
  return data;
};

export const apiConfirmStrategyTradeSell = async (formData) => {
  const { data } = await axios.post(routes.confirmStrategyTradeSell, formData);
  return data;
};

export const apiDeleteStrategy = async (strategyId) => {
  const { data } = await axios.delete(routes.deleteStrategy, {
    params: { strategyId },
  });
  return data;
};

export const apiUpdateStrategy = async (strategyId, formData) => {
  const { data } = await axios.put(routes.updateStrategy, formData, {
    params: { strategyId },
  });
  return data;
};

export const apiCreateNewStrategyNote = async (strategyId, formData) => {
  const { data } = await axios.post(routes.createNewStrategyNote, formData, {
    params: { strategyId },
  });
  return data;
};

export const apiUpdateStrategyNote = async (strategyId, formData) => {
  const { data } = await axios.put(routes.updateStrategyNote, formData, {
    params: { strategyId },
  });
  return data;
};

export const apiDeleteStrategyNote = async (strategyId, noteId) => {
  const { data } = await axios.delete(routes.deleteStrategyNote, {
    params: { strategyId, noteId },
  });
  return data;
};

export const apiGetStrategyActivity = async (strategyId) => {
  const { data } = await axios.get(routes.getStrategyActivity, {
    params: { strategyId },
  });
  return data;
};

export const apiGetStrategyById = async (strategyId) => {
  const { data } = await axios.get(routes.getStrategyById, {
    params: { strategyId },
  });
  return data;
};

export const apiGetTopPerformers = async () => {
  const { data } = await axios.get(routes.getTopPerformers, {
    params: {},
  });
  return data;
};
