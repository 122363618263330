import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { apiGetAvailableTasks, apiTriggerAutomationTask } from "api/apiAdmin";
import { Button } from "antd";

const TriggerNewTask = (props) => {
  const [availableTaskTypes, setAvailableTaskTypes] = useState([]);

  const onTriggerAutomationTask = async (taskType) => {
    await apiTriggerAutomationTask(taskType);
  };

  const returnAvailableTaskTypes = async () => {
    const taskTypes = await apiGetAvailableTasks();
    setAvailableTaskTypes(taskTypes);
  };

  useEffect(() => {
    returnAvailableTaskTypes();
  }, []);

  return (
    <div className="flex gap-3 pb-4 flex-wrap">
      {availableTaskTypes.map((taskType) => (
        <Button
          key={taskType}
          type="primary"
          onClick={() => onTriggerAutomationTask(taskType)}
        >
          {taskType}
        </Button>
      ))}
    </div>
  );
};

TriggerNewTask.propTypes = {};

export default TriggerNewTask;
