import React, { useState, useEffect } from "react";
import { apiGetModelsList } from "api/apiAiModels";
import { apiCheckAiSymbol } from "api/apiAiResults";
import dayjs from "dayjs";
import dayjsBusinessDays from "dayjs-business-days";
import { v4 as uuidv4 } from "uuid";

import { Form } from "antd";

import SavePredictionsChooseModel from "./SavePredictionsChooseModel";
import SavePredictionsForm from "./SavePredictionsForm";
import pLimit from "p-limit";

dayjs.extend(dayjsBusinessDays);

const AiMagician = () => {
  const [form] = Form.useForm();
  const [modelsList, setModelsList] = useState([]);

  const getModelsList = async () => {
    const response = await apiGetModelsList();
    setModelsList(response);
  };

  const prepareFormData = (modelObj, reqId) => {
    const values = form.getFieldsValue();
    // deduct some days before start date because of indicators calculation
    const daysToDeduct = -21;
    const startDate = values.dates[0].add(daysToDeduct, "d");
    const endDate = values.dates[1];

    return {
      reqId,
      symbol: values.symbols,
      modelId: modelObj._id,
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.add(1, "d").format("YYYY-MM-DD"),
      percToGain: +((modelObj.results.perc_to_gain - 1) * 100).toFixed(2),
      save_predictions: true,
      days: modelObj.results.days,
      indicators: modelObj.results.indicators,
    };
  };

  const handleCheckAiSymbol = async (
    model,
    onSuccess,
    onFailure,
    onProgressUpdate
  ) => {
    try {
      await form.validateFields();
      const reqId = uuidv4();
      let selectedModelsList = [model];
      if (!model) {
        selectedModelsList = modelsList;
      }
      const tasks = [];
      selectedModelsList.forEach((modelObj) => {
        const task = prepareFormData(modelObj, reqId);
        tasks.push(task);
      });
      let finishedTasks = 0;

      const handleTask = async (i) => {
        await apiCheckAiSymbol(tasks[i]);
        finishedTasks++;
        if (onProgressUpdate) {
          const model = selectedModelsList.find((item) => {
            return item._id === tasks[i].modelId;
          });
          const modelName = model?.name || "";

          onProgressUpdate(
            ((finishedTasks * 100) / tasks.length).toFixed(2),
            modelName
          );
        }
      };

      const limit = pLimit(10);

      const promises = tasks.map((task, i) => {
        return limit(() => handleTask(i));
      });
      await Promise.all(promises);

      onSuccess();
    } catch (err) {
      onFailure();
      console.log(err);
    }

    form.setFieldValue("symbol", []);
  };

  useEffect(() => {
    document.title = "Save Predictions | Dindicator Dashboard";
  }, []);

  useEffect(() => {
    getModelsList();
  }, []);

  return (
    <div className="ai-magician-wrapper">
      <div className="cards-wrapper top">
        <SavePredictionsForm form={form} />
        <SavePredictionsChooseModel
          modelsList={modelsList}
          getModelsList={getModelsList}
          handleCheckAiSymbol={handleCheckAiSymbol}
        />
      </div>
    </div>
  );
};

export default AiMagician;
