import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import LivePnl from "./Cards/LivePnl";
import RealizedPnl from "./Cards/RealizedPnl";
import UnrealizedPnl from "components/OpenPositions/UnrealizedPnl";
import PortfolioBalance from "components/OpenPositions/PortfolioBalance";

const StrategiesOverview = (props) => {
  const { strategies } = props;
  const [openPositions, setOpenPositions] = useState([]);
  const [closedPositions, setClosedPositions] = useState([]);

  useEffect(() => {
    const allOpenPositions = strategies
      .map((strategy) => strategy.openPositions)
      .flat();
    const allClosedPositions = strategies
      .map((strategy) => strategy.transactions)
      .flat();
    setOpenPositions(allOpenPositions);
    setClosedPositions(allClosedPositions);
  }, [strategies]);

  return (
    <div className="cards-wrapper">
      <LivePnl
        openPositions={openPositions}
        closedPositions={closedPositions}
      />
      <RealizedPnl closedPositions={closedPositions} strategies={strategies} />
      <UnrealizedPnl openPositions={openPositions} />
      <PortfolioBalance openPositions={openPositions} strategies={strategies} />
    </div>
  );
};

StrategiesOverview.propTypes = {
  strategies: PropTypes.array,
};

export default StrategiesOverview;
