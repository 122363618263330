import React, { useState } from "react";
import { apiGetOrderTrackerToken } from "api/apiUser";
import GenericCard from "components/UI/GenericCard/GenericCard";
import { CopyOutlined } from "@ant-design/icons";
import { App, Button } from "antd";
import { copyToClipboard } from "utils/helpers";

const AutomationToken = () => {
  const { message, modal } = App.useApp();

  const [isLoading, setIsLoading] = useState(false);
  const getToken = async () => {
    try {
      setIsLoading(true);
      const token = await apiGetOrderTrackerToken();
      copyToClipboard(token);
      setTimeout(() => {
        setIsLoading(false);
      }, 4000);
      message.success("Token copied to clipboard successfully");
    } catch (err) {
      message.success("Failed to get token");
    }
  };

  const onOpenModal = () => {
    modal.info({
      title: "Get automation token",
      content: (
        <div>
          <p className="pb-4">
            1. Click the following button to copy your token to clipboard.
          </p>
          <Button
            icon={<CopyOutlined />}
            onClick={getToken}
            loading={isLoading}
          >
            Get Token
          </Button>
          <p className="pt-4">
            2. Insert your token inside{" "}
            <span className="italic">"config.yml"</span> file in automation
            program folder
          </p>
        </div>
      ),
    });
  };

  return (
    <GenericCard title="Get automation token">
      <Button onClick={onOpenModal}>Get Token</Button>
    </GenericCard>
  );
};

export default AutomationToken;
