import React, { useState } from "react";
import PropTypes from "prop-types";
import { QRCodeSVG } from "qrcode.react";
import GenericCard from "components/UI/GenericCard/GenericCard";
import { useSelector } from "react-redux";
import { Button } from "antd";
import { apiUnlinkTelegramAccount } from "api/apiUser";

const LinkTelegramAccount = () => {
  const idToken = useSelector((state) => state.main.user._id);
  const hasTelegramLinked = useSelector((state) => state.main.user.telegramId);
  const telegramAccountLinkUrl = `https://t.me/dindicator_bot?start=${idToken}`;
  const [isLoading, setIsLoading] = useState(false);

  const onUnlinkTelegram = async () => {
    setIsLoading(true);
    await apiUnlinkTelegramAccount();
    setIsLoading(false);
  };

  return (
    <GenericCard
      title={
        <span className={hasTelegramLinked ? "green" : ""}>
          Link Telegram account
        </span>
      }
    >
      <div style={{ paddingBlockEnd: 10 }}>
        {hasTelegramLinked ? (
          <>
            <p className="green pb-4">Your Telegram account is linked</p>
            <Button loading={isLoading} onClick={onUnlinkTelegram}>
              Unlink account
            </Button>
          </>
        ) : (
          <div>
            <p className="pb-6 red">Your Telegram account is not linked</p>
            <div className="hidden md:block pb-2">
              <p className="pb-4">
                Scan the code below to link telegram account
              </p>
              <QRCodeSVG
                value={telegramAccountLinkUrl}
                includeMargin
                bgColor={"#eeeeee"}
              />
            </div>
            <a target="_blank" rel="noreferrer" href={telegramAccountLinkUrl}>
              Click here to link your account
            </a>
          </div>
        )}
      </div>
    </GenericCard>
  );
};

LinkTelegramAccount.propTypes = {
  completed: PropTypes.bool,
};

export default LinkTelegramAccount;
