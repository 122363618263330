import React, { useState } from "react";
import PropTypes from "prop-types";
import { paths } from "utils/constants";
import { apiDeleteModel } from "api/apiAiModels";
import { useNavigate } from "react-router-dom";
import CardPlaceholder from "components/UI/GenericCard/CardPlaceholder";
import { Alert, App, Button } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { toFixed } from "utils/helpers";

const ModelCreationStatus = (props) => {
  const {
    requestStatus,
    setRequestStatus,
    isPredicting,
    predictAllModelHistory,
    modelData,
    setModelData,
  } = props;

  const navigate = useNavigate();
  const { message, modal } = App.useApp();
  const [deleteModelPending, setDeleteModelPending] = useState(false);

  const onUseCreatedModel = () => {
    navigate(paths.ai);
  };

  const deleteModelById = async () => {
    try {
      setDeleteModelPending(true);
      await apiDeleteModel(requestStatus);
      setRequestStatus("");
      setModelData(null);
      message.success("Model deleted successfully");
    } catch (err) {
      message.error("Error deleting model");
    }
    setDeleteModelPending(false);
  };

  const showDeleteConfirm = () => {
    modal.confirm({
      title: "Are you sure you want to delete this model?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteModelById();
      },
      onCancel() {},
    });
  };

  const returnStatus = () => {
    console.log(requestStatus);
    if (!requestStatus) return null;
    else if (requestStatus === "error") {
      // model creation failed
      return (
        <Alert
          type="error"
          message="Failed to create model!"
          showIcon
          description="Try selecting different dates."
        />
      );
    } else if (isPredicting) {
      // model is predicting
      return (
        <Alert
          type="info"
          message="Model is predicting..."
          description="Wait a few moments until the model finishes predicting."
          showIcon
          closable
        />
      );
    } else if (
      requestStatus !== "" &&
      requestStatus !== "error" &&
      requestStatus !== "success"
    ) {
      // model created successfully need to change content
      return (
        <Alert
          type="success"
          message="Model created successfully!"
          description={
            <div>
              <p>Click the button below to save the predictions.</p>
              <p>You can also delete the model if you want.</p>
            </div>
          }
          action={
            <div className="flex flex-col gap-4">
              <Button type="dashed" onClick={predictAllModelHistory}>
                Save predictions
              </Button>
              <Button
                danger
                ghost
                onClick={() => showDeleteConfirm()}
                loading={deleteModelPending}
              >
                Delete model
              </Button>
            </div>
          }
          // showIcon
          closable
        />
      );
    } else if (requestStatus === "success") {
      // model finished predicting
      return (
        <Alert
          type="success"
          message="Model created successfully!"
          description="You can view you new model in AI Backtests page."
          showIcon
          action={
            <Button size="small" type="text" onClick={onUseCreatedModel}>
              Try it now!
            </Button>
          }
          closable
        />
      );
    }
  };

  return (
    <CardPlaceholder>
      {returnStatus()}
      {modelData && (
        <div className="pt-4">
          <h3>Model details</h3>
          <p>Accuracy: {toFixed(modelData.results.accuracy * 100)}%</p>
          <p>TP: {toFixed(modelData.results.tp * 100)}%</p>
          <p>TN: {toFixed(modelData.results.tn * 100)}%</p>
        </div>
      )}
    </CardPlaceholder>
  );
};

ModelCreationStatus.propTypes = {
  requestStatus: PropTypes.string.isRequired,
  setRequestStatus: PropTypes.func.isRequired,
  isPredicting: PropTypes.bool.isRequired,
  predictAllModelHistory: PropTypes.func.isRequired,
  modelData: PropTypes.object,
  setModelData: PropTypes.func.isRequired,
};

export default ModelCreationStatus;
