import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Button, Tabs } from "antd";
import UserDetails from "./UserDetails";
import LogsReport from "./LogsReport";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { apiGetUserDetailsById } from "api/apiAdmin";
import OnlineUserActivity from "./OnlineUserActivity";
import SearchTradesTable from "../EditTrades/SearchTradesTable";

const defaultTab = "details";

const UserCard = (props) => {
  const { userId, onBackButton } = props;
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const [user, setUser] = useState(null);

  const handleChangeTab = (tabKey) => {
    setSelectedTab(tabKey);
  };

  const fetchUserDetails = async () => {
    try {
      const user = await apiGetUserDetailsById(userId);
      setUser(user);
    } catch (err) {
      console.error(err);
      onBackButton();
    }
  };

  const items = useMemo(() => {
    const tabOptions = [
      {
        key: "details",
        label: `Details`,
      },
      {
        key: "logs",
        label: `Logs`,
      },
      {
        key: "trades",
        label: `Trades`,
      },
    ];
    if (user) {
      tabOptions.push({ key: "online", label: "Online" });
    }
    return tabOptions;
  }, [user]);

  useEffect(() => {
    handleChangeTab(defaultTab);
  }, [user]);

  useEffect(() => {
    fetchUserDetails();
  }, [userId]);

  const renderTabContent = () => {
    switch (selectedTab) {
      case "details":
        return <UserDetails selectedUser={user} setSelectedUser={setUser} />;
      case "logs":
        return <LogsReport selectedUser={user} />;
      case "online":
        return <OnlineUserActivity selectedUser={user} />;
      case "trades":
        return <SearchTradesTable userId={userId} />;

      default:
        break;
    }
  };

  return (
    <>
      <Button type="link" onClick={onBackButton} icon={<ArrowLeftOutlined />}>
        Back
      </Button>
      <h2 className="py-2 font-medium">
        {user
          ? `${user.firstName || "Not registered"} ${user.lastName || ""} - ${
              user.username
            }`
          : "User Details"}
      </h2>
      <Tabs
        defaultActiveKey="1"
        items={items}
        activeKey={selectedTab}
        onChange={handleChangeTab}
      />
      {user && renderTabContent()}
    </>
  );
};

UserCard.propTypes = { userId: PropTypes.string, onBackButton: PropTypes.func };

export default UserCard;
