import React from "react";
import PropTypes from "prop-types";
import GenericCard from "../UI/GenericCard/GenericCard";
import DashedTooltip from "components/UI/DashedTooltip";

const ProfitFactor = (props) => {
  const { backtestResults } = props;

  return (
    <GenericCard
      title="Profit factor"
      tooltipText="The amount of money a trading strategy made for every unit of money it lost. This value is calculated by dividing gross profits by gross losses."
    >
      <h2>{backtestResults.metrics.profitFactor}</h2>
      <div className="flex gap-3">
        <div>
          <h5>
            <DashedTooltip text="The total profit for all profitable trades generated by a strategy.">
              Gross profit
            </DashedTooltip>
          </h5>
          <h3>${backtestResults.metrics.grossProfit.toLocaleString()}</h3>
        </div>
        <div>
          <h5>
            <DashedTooltip text="The total losses for all losing trades generated by a strategy.">
              Gross Loss
            </DashedTooltip>
          </h5>
          <h3>${backtestResults.metrics.grossLoss.toLocaleString()}</h3>
        </div>
      </div>

      <div className="flex gap-6">
        <div>
          <h5>
            <DashedTooltip
              text={`The Tail Ratio measures the magnitude of extreme gains relative to extreme losses by comparing the 95th to the 5th percentile of returns. A higher Tail Ratio implies that profits in the best trades significantly outweigh the worst losses, helping gauge a strategy’s resilience to extreme downside events.`}
            >
              Tail Ratio
            </DashedTooltip>
          </h5>
          <h3>
            {backtestResults.metrics?.tailRatio
              ? backtestResults.metrics?.tailRatio?.toLocaleString()
              : 0}
          </h3>
        </div>
        <div>
          <h5>
            <DashedTooltip
              text={`
The Common Sense Ratio combines a strategy’s profit factor with its tail ratio to provide a holistic view of both profitability and risk. By multiplying the profit factor (total profits vs. total losses) with the tail ratio (comparing extreme gains to losses), it offers insight into a strategy’s ability to generate consistent returns while minimizing exposure to extreme losses.
              `}
            >
              Common Sense Ratio
            </DashedTooltip>
          </h5>
          <h3>
            {backtestResults.metrics?.commonSenseRatio
              ? backtestResults.metrics?.commonSenseRatio?.toLocaleString()
              : 0}
          </h3>
        </div>
      </div>
    </GenericCard>
  );
};
ProfitFactor.propTypes = {
  backtestResults: PropTypes.object,
};

export default ProfitFactor;
