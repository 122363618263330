import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Input } from "antd";
import AntTable from "components/UI/AntTable";
import dayjs from "dayjs";
import { apiSearchUser } from "api/apiAdmin";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const { Search } = Input;

const UsersTable = (props) => {
  const { handleSelectUser, setSearchResults, searchResults } = props;
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 50,
    },
  });

  const handleSearchUsers = async (searchInput, pageSize, currentPage) => {
    setLoading(true);
    try {
      const response = await apiSearchUser({
        q: searchInput,
        pageSize,
        currentPage,
      });
      setSearchResults(response.results);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response.total,
          showSizeChanger: true,
          showQuickJumper: true,
          position: ["bottomLeft"],
        },
      });
    } catch (err) {}
    setLoading(false);
  };

  useEffect(() => {
    handleSearchUsers(
      searchInput,
      tableParams.pagination.pageSize,
      tableParams.pagination.current
    );
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      // setData([]);
    }
  };

  const sortByNumber = useCallback((a, b) => {
    // handle null / N/A values
    if (a === b) return 0;
    if (a === null || a === "N/A") return -1;
    if (b === null || b === "N/A") return 1;
    return a > b ? 1 : -1;
  }, []);

  const sortByText = useCallback((a, b) => {
    return a
      ?.toString()
      ?.toLowerCase()
      ?.localeCompare(b?.toString()?.toLowerCase());
  }, []);

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      sorter: {
        compare: (a, b) => sortByText(a.firstName, b.firstName),
      },
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      sorter: {
        compare: (a, b) => sortByText(a.lastName, b.lastName),
      },
    },
    {
      title: "Username",
      dataIndex: "username",
      sorter: {
        compare: (a, b) => sortByText(a.username, b.username),
      },
    },
    {
      title: "Email",
      width: 220,
      dataIndex: "email",
      sorter: {
        compare: (a, b) => sortByText(a.email, b.email),
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      sorter: {
        compare: (a, b) => sortByText(a.role, b.role),
      },
    },
    {
      title: "User Status",
      dataIndex: "enabled",
      render: (text) =>
        text ? <span>Active</span> : <span className="red">Blocked</span>,
      sorter: {
        compare: (a, b) => sortByText(a.enabled, b.enabled),
      },
    },
    {
      title: "Automation",
      dataIndex: ["automation", "enabled"],
      render: (text) =>
        text ? (
          <CheckOutlined style={{ fontSize: 22, color: "#00c900" }} />
        ) : (
          <CloseOutlined style={{ fontSize: 22, color: "#ff0000" }} />
        ),
      sorter: {
        compare: (a, b) =>
          sortByText(a.automation.enabled, b.automation.enabled),
      },
    },
    {
      title: "Last login",
      dataIndex: "lastLogin",
      render: (text) => dayjs(text).format("HH:mm DD/MM/YY"),
      sorter: {
        compare: (a, b) => sortByNumber(a.lastLogin, b.lastLogin),
      },
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      render: (text) => dayjs(text).format("HH:mm DD/MM/YY"),
      sorter: {
        compare: (a, b) => sortByNumber(a.createdAt, b.createdAt),
      },
    },
    {
      title: "",
      dataIndex: "_id",
      render: (text) => (
        <Button onClick={() => handleSelectUser(text)}>Select</Button>
      ),
    },
  ];

  return (
    <div className="flex flex-col">
      <Search
        onChange={(e) => setSearchInput(e.target.value)}
        className="max-w-[300px]"
        placeholder="Search user"
        enterButton
        onSearch={() =>
          handleSearchUsers(
            searchInput,
            tableParams.pagination.pageSize,
            tableParams.pagination.current
          )
        }
      />
      <div className="pt-4">
        <AntTable
          rowKey="_id"
          dataSource={searchResults}
          columns={columns}
          onChange={handleTableChange}
          pagination={tableParams.pagination}
          loading={loading}
        />
      </div>
    </div>
  );
};

UsersTable.propTypes = {
  handleSelectUser: PropTypes.func.isRequired,
  setSearchResults: PropTypes.func.isRequired,
  searchResults: PropTypes.array,
};

export default UsersTable;
