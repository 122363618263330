import React, { useCallback, useEffect, useState } from "react";
import { Button, Divider } from "antd";
import { apiFetchRecentTasks } from "api/apiAdmin";
import GenericCard from "components/UI/GenericCard/GenericCard";
import ProtectedContent from "components/UI/ProtectedContent";
import dayjs from "dayjs";
import AntTable from "components/UI/AntTable";
import TriggerNewTask from "./TriggerNewTask";
import TaskLogsModal from "./TaskLogsModal";

const RecentTasks = () => {
  const [recentTasks, setRecentTasks] = useState([]);

  const sortByNumber = useCallback((a, b) => {
    // handle null / N/A values
    if (a === b) return 0;
    if (a === null || a === "N/A") return -1;
    if (b === null || b === "N/A") return 1;
    return a > b ? 1 : -1;
  }, []);

  const sortByText = useCallback((a, b) => {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  }, []);

  const columns = [
    {
      title: "Logs",
      dataIndex: "_id",
      render: (text) => {
        return <TaskLogsModal taskId={text} />;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: {
        compare: (a, b) => sortByText(a.status, b.status),
      },
    },
    {
      title: "Task",
      dataIndex: "eventType",
      sorter: {
        compare: (a, b) => sortByText(a.eventType, b.eventType),
      },
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      render: (text) => <>{dayjs(text).format("DD/MM/YY HH:mm:ss")}</>,
      sorter: {
        compare: (a, b) => sortByText(a.startTime, b.startTime),
      },
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      render: (text) => <>{dayjs(text).format("DD/MM/YY HH:mm:ss")}</>,
      sorter: {
        compare: (a, b) => sortByText(a.endTime, b.endTime),
      },
    },
    {
      title: "Elapsed Time",
      dataIndex: "elapsedTime",
      render: (text, properties) => {
        const end = properties.endTime
          ? new Date(properties.endTime)
          : new Date();
        const elapsedTime = (end - new Date(properties.startTime)) / 1000;
        return <>{elapsedTime} Seconds</>;
      },
    },
  ];
  const fetchRecentTasks = async () => {
    try {
      const response = await apiFetchRecentTasks();
      setRecentTasks(response);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchRecentTasks();
    document.title = "Recent Tasks | Dindicator Dashboard";
    const interval = setInterval(fetchRecentTasks, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Divider orientation="left">Recent Tasks</Divider>
      <div className="cards-wrapper">
        <ProtectedContent>
          <GenericCard title="General tools">
            <TriggerNewTask />
            <Button onClick={fetchRecentTasks} className="mb-4">
              Refresh
            </Button>
            <AntTable
              rootClassName="predictions-table"
              rowKey="_id"
              dataSource={recentTasks}
              columns={columns}
              scroll={{
                x: 600,
                y: 400,
              }}
            />
          </GenericCard>
        </ProtectedContent>
      </div>
    </>
  );
};

export default RecentTasks;
