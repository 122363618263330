import React, { useEffect, useRef, useState } from "react";
import { Form } from "antd";

import { Pie, G2 } from "@ant-design/plots";
import GenericCard from "components/UI/GenericCard/GenericCard";
const noneSelected = [{ type: "None selected", value: 0 }];

export const SelectedSectorsChart = (props) => {
  const { symbolsDict, form } = props;
  const [sectorsDist, setSectorsDist] = useState(noneSelected);
  const selectedSymbols = Form.useWatch("leads", form);

  const chartRef = useRef(null);
  const G = G2.getEngine("canvas");

  const config = {
    appendPadding: 10,
    data: sectorsDist,
    angleField: "value",
    colorField: "type",
    radius: 0.9,
    legened: false,
    label: {
      type: "spider",
      labelHeight: 40,
      formatter: (data, mappingData) => {
        const group = new G.Group({});
        group.addShape({
          type: "circle",
          attrs: {
            x: 0,
            y: 0,
            width: 40,
            height: 50,
            r: 5,
            fill: mappingData.color,
          },
        });
        group.addShape({
          type: "text",
          attrs: {
            x: 10,
            y: 8,
            text: `${data.type}`,
            fill: mappingData.color,
            fontSize: 12,
          },
        });
        group.addShape({
          type: "text",
          attrs: {
            x: 0,
            y: 25,
            text: `${data.value}% (${data.amount})`,
            fill: "rgba(250, 250, 250)",
            fontWeight: 700,
          },
        });
        return group;
      },
    },
    height: 230,
    interactions: [
      {
        type: "element-active",
      },
    ],
  };

  const handleSectorsPieChart = () => {
    const sectors = selectedSymbols.reduce((acc, item) => {
      const symbol = symbolsDict[item];
      acc[symbol.sector.name] = acc[symbol.sector.name]
        ? [...acc[symbol.sector.name], item]
        : [item];
      return acc;
    }, {});
    const sectorsData = Object.entries(sectors).map((sector) => {
      return {
        type: sector[0],
        value: +((sector[1].length / selectedSymbols.length) * 100).toFixed(2),
        amount: sector[1].length,
      };
    });

    setSectorsDist(sectorsData);
  };

  useEffect(() => {
    if (selectedSymbols?.length) {
      handleSectorsPieChart();
    } else {
      setSectorsDist(noneSelected);
      if (chartRef.current && chartRef.current.getChart()) {
        chartRef.current.getChart().changeData(noneSelected);
      }
    }
  }, [selectedSymbols]);

  return (
    <GenericCard title="Selected Sectors">
      <Pie ref={chartRef} {...config} autoFit={false} height={320} />
    </GenericCard>
  );
};

export default React.memo(SelectedSectorsChart);
