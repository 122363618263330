import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { Button, Slider } from "antd";

const RangeSlider = (props) => {
  const { predictionsTable, handleChangeTradingRange, startDate, endDate } =
    props;

  const [isShiftPressed, setIsShiftPressed] = useState(false);
  const shiftPressedRef = useRef(false); // To hold the state outside re-renders

  const [dates, setDates] = useState([
    +new Date(predictionsTable[0].date),
    +new Date(predictionsTable[predictionsTable.length - 1].date),
  ]);
  const [years, setYears] = useState([]);

  const onAfterChange = (values) => {
    // set min and max values if they are out of range
    if (values[0] < +new Date(predictionsTable[0].date)) {
      values[0] = +new Date(predictionsTable[0].date);
    }
    if (
      values[1] > +new Date(predictionsTable[predictionsTable.length - 1].date)
    ) {
      values[1] = +new Date(predictionsTable[predictionsTable.length - 1].date);
    }
    values = [+new Date(values[0]), +new Date(values[1])];
    handleChangeTradingRange(values);
    setDates(values);
  };

  const listYearsBetweenDates = (startDate, endDate) => {
    let startYear = new Date(startDate).getFullYear();
    let endYear = new Date(endDate).getFullYear();
    let years = [];

    for (let year = startYear; year <= endYear; year++) {
      years.push({
        year: year,
        label: year,
        dates: [new Date(year + "-01-01"), new Date(year + "-12-31")],
      });
    }

    return years;
  };

  // get number of years between two dates
  useMemo(() => {
    const yearsBetween = listYearsBetweenDates(
      predictionsTable[0].date,
      predictionsTable[predictionsTable.length - 1].date
    );
    setYears(yearsBetween);
  }, [startDate, endDate]);

  const getDelta = (vals, newVals) => {
    const d0 = newVals[0] - vals[0];
    const d1 = newVals[1] - vals[1];
    return d0 === 0 ? d1 : d0;
  };

  const handleChange = (values) => {
    if (!isShiftPressed && !shiftPressedRef.current) {
      setDates(values);
      return;
    }
    setDates((prevState) => {
      if (prevState[0] === values[0] && prevState[1] === values[1]) {
        return values;
      }
      let d = getDelta(prevState, values);

      const vals2 = prevState.map((v) => v + d);

      // Ensure the new values stay within bounds
      const minDate = +new Date(predictionsTable[0].date);
      const maxDate = +new Date(
        predictionsTable[predictionsTable.length - 1].date
      );

      if (vals2[0] < minDate) {
        return [minDate, minDate + prevState[1] - prevState[0]];
      }
      if (vals2[1] > maxDate) {
        return [maxDate - prevState[1] + prevState[0], maxDate];
      }

      return [
        Math.max(minDate, Math.min(vals2[0], maxDate)),
        Math.max(minDate, Math.min(vals2[1], maxDate)),
      ];
    });
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Shift") {
        setIsShiftPressed(true);
        shiftPressedRef.current = true;
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Shift") {
        setIsShiftPressed(false);
        shiftPressedRef.current = false;
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);
  return (
    <div className="w-full max-w-[500px]">
      <h4 className="pb-1">
        Backtesting Range: {dayjs(startDate).format("DD/MM/YY")} -{" "}
        {dayjs(endDate).format("DD/MM/YY")}
      </h4>
      <Slider
        range={
          {
            // draggableTrack: true,
          }
        }
        onAfterChange={onAfterChange}
        min={+new Date(predictionsTable[0].date)}
        max={+new Date(predictionsTable[predictionsTable.length - 1].date)}
        step={86400000}
        tooltip={{
          formatter: (value) => `${dayjs(value).format("DD/MM/YY")}`,
        }}
        value={dates}
        onChange={handleChange}
      />
      {years.map((year) => (
        <Button
          key={year.year}
          size="small"
          className="mr-2"
          onClick={() => onAfterChange(year.dates)}
        >
          {year.label}
        </Button>
      ))}
    </div>
  );
};

RangeSlider.propTypes = {
  predictionsTable: PropTypes.array.isRequired,
  handleChangeTradingRange: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
};

export default RangeSlider;
