import { createSlice } from "@reduxjs/toolkit";
import {
  fetchSectors,
  fetchSymbols,
  fetchUserData,
  onCompleteRegister,
  onLoginSubmit,
  onUserLogout,
  updateUserPremiumFee,
} from "store/asyncThunk";

// User initial state
const initialState = {
  user: null,
  isDrawerOpen: true,
  sectorsList: [],
  allSymbols: [],
  symbolsList: [],
  symbolsDict: {},
  symbolsGroupedInSectored: [],
  accountType: "real",
};

export const main = createSlice({
  name: "main",
  initialState,
  reducers: {
    toggleDrawer: (state, action) => {
      state.isDrawerOpen = action.payload ?? !state.isDrawerOpen;
    },
    toggleRealDemo: (state, action) => {
      state.accountType = state.accountType === "demo" ? "real" : "demo";
    },
  },
  // Reducers for async actions
  extraReducers: (builder) => {
    builder.addCase(fetchUserData.fulfilled, (state, action) => {
      state.user = action.payload;
    });

    builder.addCase(fetchUserData.rejected, (state, action) => {
      state.user = undefined;
    });

    builder.addCase(onLoginSubmit.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(onCompleteRegister.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(onUserLogout.fulfilled, (state, action) => {
      state.user = undefined;
    });

    builder.addCase(fetchSectors.fulfilled, (state, action) => {
      state.sectorsList = action.payload;
    });
    builder.addCase(fetchSymbols.fulfilled, (state, action) => {
      state.allSymbols = action.payload.allSymbols;
      state.symbolsList = action.payload.symbolsList;
      state.symbolsDict = action.payload.symbolsDict;
      state.symbolsGroupedInSectored = action.payload.symbolsGroupedInSectored;
    });
    builder.addCase(updateUserPremiumFee.fulfilled, (state, action) => {
      state.user.preferences.premiumFee = action.payload;
    });
  },
});

/* eslint-enable no-unused-vars */

export const selectAutomationAllowed = (state) =>
  state.main.user?.automation?.enabled;

// Export actions under reducers
export const { toggleDrawer, toggleRealDemo } = main.actions;
export default main.reducer;
